export default {
  firstName: null,
  lastName: null,
  middleName: null,
  birthdayDate: null,
  birthdayDateFormated: null,
  age: null,
  gender: null,
  snils: null,
  login: null,
  password: null,
  passwordRepeated: null,
  contacts: {
    address: null,
    house: null,
    building: null,
    flat: null,
    email: null,
    phone: null,
  },
  education: {
    general: {
      type: null,
      name: null,
      language: null,
      grade: null,
      remote: false,
    },
    professional: {
      type: null,
      name: null,
      grade: null,
    },
  },
  parents: {
    parentOne: {
      firstName: null,
      lastName: null,
      middleName: null,
      phone: null,
      email: null,
    },
    parentTwo: {
      firstName: null,
      lastName: null,
      middleName: null,
      phone: null,
      email: null,
    },
  },
}