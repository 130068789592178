<template>
  <div>
    <template v-if="attachExtension ==='image'">
      <viewer 
      class="text-left mt-2" 
      :options="imageOptions"
      >
        <img loading="lazy" class="preview-image" :src="`data:image/jpeg;base64,${encodeURI(attach.data)}`">
      </viewer>
    </template>
    
    <template v-else-if="attachExtension ==='pdf'">
      <span :class="'text-body-1 font-weight-half-normal'">
        Файл:
      </span>
      <span 
        :class="'text-body-1 file-link'"
        @click="openPdf"
      >
        {{attach.name ? attach.name : "Нет названия"}}
      </span>
    </template>
    
    <template v-else-if="attachExtension ==='rar'">
      <span class="text-body-1 font-weight-half-normal">
        Файл:
      </span>
      <span 
        :class="'text-body-1 file-link'"
        @click="downloadRar"
      >
        {{attach.name ? attach.name : "Нет названия"}}
      </span>
    </template>

    <template v-else-if="attachExtension ==='zip'">
      <span class="text-body-1 font-weight-half-normal">
        Файл:
      </span>
      <span 
        :class="'text-body-1 file-link'"
        @click="downloadZip"
      >
        {{attach.name ? attach.name : "Нет названия"}}
      </span>
    </template>
    
    <template v-else-if="attachExtension ==='none'">
      <v-icon left color="#00AEBA" size="30" class="pb-1">mdi-file-cancel-outline</v-icon>
      <span class="text-body-1 font-weight-half-normal">Файл отсутствует</span>
    </template>

    <template v-else>
      <v-icon left color="#00AEBA" size="30" class="pb-1">mdi-file-cancel-outline</v-icon>
      <span class="text-body-1 font-weight-half-normal">Недопустимый файл</span>
    </template>
  </div>
</template>

<script>
export default {
  name: "Attachment",
  data() {
    return {
      imageOptions: { 
        "button": true, 
        "navbar": false, 
        "title": true, 
        "toolbar": false, 
        "tooltip": false, 
        "movable": false, 
        "zoomable": true, 
        "rotatable": false, 
        "scalable": false, 
        "transition": true, 
        "fullscreen": false, 
        "keyboard": true 
      }
    }
  },
  props: {
    isShow: Boolean,
    type: String,
    attach: Object
  },
  computed: {
    attachExtension() {
      if(this.attach && this.attach.name && this.attach.data) {
        const splitedFileName = this.attach.name.split('.');
        const fileType = splitedFileName[splitedFileName.length - 1];
        var firstChar = this.attach.data.charAt(0);
        if(firstChar === '/' || firstChar === 'i') return 'image';
        return fileType;
      }
      return 'none';
    }
  },
  methods: {
    attachByteArray(){
      const fileData = this.attach;
      let byteCharacters = atob(fileData.data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      return new Uint8Array(byteNumbers);
    },
    openPdf() {
      let file = new Blob([this.attachByteArray()], { type: 'application/pdf;base64' });
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    },
    downloadZip() {
      let file = new Blob([this.attachByteArray()], { type: 'octet/stream' });
      var a = document.createElement('a');
      a.download = this.attach.name;
      a.href = URL.createObjectURL(file);
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    downloadRar() {
      let file = new Blob([this.attachByteArray()], { type: 'application/x-rar-compressed' });
      var a = document.createElement('a');
      a.download = this.attach.name;
      a.href = URL.createObjectURL(file);
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
  }
};
</script>

<style lang="scss">
  .preview-image {
    object-fit: contain;
    
    max-width: 275px;
    max-height: 175px;
    cursor: pointer;
    padding: 5px;
    display: inline-block;
    opacity: 0.8;
    transition: opacity 0.4s ease-in-out;

    &:hover {
      opacity: 1;
      transition: opacity 0.4s ease-in-out;
    }
  }  
  .file-link {
    cursor: pointer !important;

    &:hover {
      color: #7690c9 !important;
      text-decoration: underline !important;
    }
  }
  .file-link__sertificate {
    cursor: pointer !important;
    color: #7690c9 !important;
    text-decoration: underline !important;
    
    &:hover {
      color: rgba(0,0,0,.87) !important;
    }
  }
</style>