<template>
  <v-row justify="center" v-if="isVisible">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="1100"
      persistent
    >
      <v-card outlined flat>
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h6 font-weight-half-medium pl-0">
            <v-icon left :color="getAchievementColor(achievement.type)" size="25" class="pb-1">{{getAchievementIcon(achievement.type)}}</v-icon>
            <span>{{achievement.name}}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#e31e25" text dark x-small @click="close">
            <v-icon left dark>mdi-window-close</v-icon>
            Закрыть
          </v-btn>
        </v-app-bar>
        <v-divider />
        <achievement-fields class="pt-3" :achievement="achievement" :isEvent="isEvent"></achievement-fields>
        <div v-if="isAchievementForAdd" class="mx-4 pt-3 pb-5 text-center">
          <v-btn
            :color="getAchievementColor(achievement.type)"
            class="ma-2"
            small
            text
            @click="$emit('select-achievement', achievement)"
          >
            <v-icon left dark>mdi-check</v-icon>
            Приложить достижение
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AchievementFieldsVue from './AchievementFields.vue'
export default {
  name: 'OrderAchievementPreview',
  props: {
    isVisible: Boolean,
    achievement: Object,
    isEvent: Function,
    getAchievementIcon: Function,
    getAchievementColor: Function,
    selectedAchievements: Array
  },
  components: {
    'achievement-fields': AchievementFieldsVue
  },
  computed: {
    isAchievementForAdd() {
      return this.selectedAchievements.filter(a => a.id === this.achievement.id).length === 0;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>