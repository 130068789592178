<template>
  <v-row justify="center" v-if="dialog">
    <v-dialog
      v-model="dialog"
      persistent
      overlay-color="white"
      overlay-opacity="1"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="1300"
    >
      <v-card outlined flat class="pb-4">
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h6 font-weight-half-medium pl-0">
            <v-icon left color="#00AEBA" size="25" class="pb-1">mdi-trophy</v-icon>
            <span>Новое достижение</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#e31e25" text dark x-small @click="cancel">
            <v-icon left dark>mdi-window-close</v-icon>
            Отмена
          </v-btn>
        </v-app-bar>
        <v-divider />
        <achievement-form
          :achievement="achievement"
          :isEvent="isEvent"
          :rules="rules"
          :eventCountries="eventCountries"
          :eventRegions="eventRegions"
          :isNew="true"
          v-on:set-processing="setProcessing"
          v-on:save="save"
        ></achievement-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AchievementFormVue from './AchievementForm.vue';
export default {
  name: "AchievementAdd",
  props: {
    dialog: Boolean,
    rules: Object,
    isEvent: Function,
  },
  data() {
    return {
      eventCountries: [],
      eventRegions: [],
      achievement: {
        id: null,
        type: null,
        name: null,
        eventType: null,
        year: null,
        eventCountry: {
          id: null,
          name: null
        },
        eventRegion: {
          id: null,
          name: null
        },
        eventCity: null,
        eventResult: null,
        eventKind: null,
        eventResultValue: null,
        perfomanceLink: null,
        attach: null
      },
    };
  },
  components: {
    'achievement-form': AchievementFormVue
  },
  methods: {
    async loadData() {
      this.$emit('set-processing', true);
      this.eventCountries = await this.$api.getCountries();
      this.eventRegions = await this.$api.getRegions();
      this.$emit('set-processing', false);
    },
    setProcessing(state) {
      this.$emit('set-processing', state);
    },
    save(data) {
      this.$emit('save', data, 'newForm');
    },
    cancel() {
      this.$emit('cancel');
    }
  },
  created() {
    this.loadData();
  }
};
</script>