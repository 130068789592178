<template>
  <v-card 
    :class="$vuetify.breakpoint.mdAndUp ? `achievement-${achievement.id} px-1` : `achievement-${achievement.id} mx-3`" 
    flat
    tile 
    outlined 
    :width="$vuetify.breakpoint.mdAndUp ? '80%' : '95%'"
  >
    <v-expansion-panels flat accordion v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header class="pl-0 pr-2 py-0 mx-0">
          <v-app-bar flat dense color="rgba(0, 0, 0, 0)" class="pr-0 mr-0">
            <v-toolbar-title 
              :style="`max-width: ${titleMaxWidth}px !important`"
              :class="panel === 0 ? 'text-body-1 font-weight-half-medium' : 'text-body-1'"
              :title="achievement.name ? achievement.name : 'Не указано'"
            >
              {{achievement.name ? achievement.name : 'Не указано'}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!achievement.isReadOnly"
              :color="editingAchievement === achievement.id ? '#e31e25' : '#7690c9'"
              text 
              dark 
              x-small 
              :disabled="editingAchievement && editingAchievement !== achievement.id" 
              @click.native.stop="achievementEditButton(achievement.id, achievement.isReadOnly)"             
            >
              <v-icon left dark>{{editingAchievement === achievement.id ? 'mdi-window-close' : 'mdi-pencil'}}</v-icon>
              {{editingAchievement === achievement.id ? 'Отмена' : 'Редактировать'}}
            </v-btn>
          </v-app-bar>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <achievement-fields 
            v-if="editingAchievement !== achievement.id"
            :achievement="achievement"
            :isEvent="isEvent"
          ></achievement-fields>
          <achievement-form 
            v-else
            :achievement="achievement" 
            :isEvent="isEvent" 
            :rules="rules"
            :eventCountries="eventCountries"
            :eventRegions="eventRegions"
            :isNew="false"
            v-on:delete-achievement="deleteAchievement"
            v-on:set-processing="setProcessing"
            v-on:show-snackbar="showSnackbar"
            v-on:save="save"
          ></achievement-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import AchievementFields from './AchievementFields.vue';
import AchievementForm from './AchievementForm.vue';

export default {
  name: 'AchievementCard',
  props: {
    rules: Object,
    achievement: Object,
    editingAchievement: null,
    isEvent: Function,
    eventCountries: Array,
    eventRegions: Array
  },
  data() {
    return {
      panel: null,
    }
  },
  computed: {
    titleMaxWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 90;
        case "sm":
          return 360;
        case "md":
          return 360;
        case "lg":
          return 700;
        case "xl":
          return 1000;
      }
    }
  },
  components: {
    'achievement-form': AchievementForm,
    'achievement-fields': AchievementFields
  },
  methods: {
    deleteAchievement(id) {
      this.$emit('delete-achievement', id);
    },
    achievementEditButton(achievementId, isReadOnly) {
      if(isReadOnly) {
        this.$emit('show-snackbar', false, 'Данное достижение нельзя редактировать, так как оно приложено к заявке.');
        return;
      }
      if(this.editingAchievement) {
        this.$emit('set-editing-achievement', null);
      }
      else {
        this.panel = 0;
        this.getAchievements();
        this.$emit('set-editing-achievement', achievementId);
      }
    },
    save(data) {
      this.$emit('save', data);
    },
    setProcessing(state) {
      this.$emit('set-processing', state);
    },
    showSnackbar(success, text) {
      this.$emit('show-snackbar', success, text);
    },
    getAchievements(){
      this.$emit('get-achievements');
    },
  },
  watch: {
    panel: {
      handler(val, oldVal) {
        if(oldVal === 0 && (val === undefined || val === null)) {
          this.$emit('set-editing-achievement', null);
        }
        else if(val === 0) {
          const target =  document.getElementsByClassName(`achievement-${this.achievement.id}`)[0];
          const options = {
            duration: 400,
            offset: 250,
            easing: 'easeInOutCubic',
          }
          setTimeout(() => {
            this.$vuetify.goTo(target, options);
          }, 200);
        }
      }
    }
  }
}
</script>
