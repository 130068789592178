<template>
    <div class="mb-5">
        <p class="text-h5 font-weight-light mt-3">Мои курсы</p>
        <v-divider></v-divider>
        <v-row class="mt-7" justify="center" align="center">
            <courses-table 
                :courses="courses"
            ></courses-table>
        </v-row>
    </div>
</template>
<script>
import CoursesTable from './CoursesTable.vue';

export default {
    name: 'Courses',
    props:{
        orders : Array,
    },
    data: () =>  ({
    }),
    computed: {
        courses(){
            return this.orders
            .filter((o) => o.course.courseGrade || o.course.courseSertificate || o.course.courseLink)
            .map((o) => {   
                o.course.courseGrade = !o.course.courseGrade ? 'Нет' : o.course.courseGrade
                return o.course
            });
        },
    },
    components: {
      'courses-table': CoursesTable
    },
    methods: {
        setProcessing(state) {
            this.$emit('set-processing', state);
        },
    },
}
</script>
<style scoped>

</style>