<template>
  <v-card
    flat
    tile
    outlined
    :width="$vuetify.breakpoint.mdAndUp ? '85%' : '95%'"
  >
    <v-data-table
      :headers="headers"
      :items="courses"
      disable-pagination
      hide-default-footer
      :loading="coursesTableLoading"
      loading-text="Загрузка курсов..."
    >
      <template v-slot:item.actions="{ item }">
        <div v-if="item.courseLink">
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="#ef7d1a"
              class="mx-1"
              size="20"
              v-bind="attrs"
              v-on="on"
              @click.native.stop="redirectOnCourse(item)"
            >
              mdi-arrow-right-thick
            </v-icon>
          </template>
          <span>Перейти к курсу</span>
        </v-tooltip>
        </div>
        <div v-else>         
        </div>
      </template>
      <template v-slot:item.courseSertificateAttach="{ item }">
            <div v-if="!item.courseSertificate">
              Отсутствует
            </div>
            <div v-else>
              <attachment
              :type="'Сертификат'"
              :attach="item.courseSertificate"                
              ></attachment>
            </div>    
      </template>
      <template v-slot:no-data>
        <span class="font-weight-light">Нет курсов</span>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import AttachmentVue from "./Attachment.vue";

export default {
  name: "CoursesTable",
  props: {
    courses: Array,
    coursesTableLoading: Boolean,
  },
  data() {
    return {
      headers: [
        {
          text: "Курс",         
          value: "name",
          sortable: true,
        },
        {
          text: "Действия",
          value: "actions",
          align: "center",
          sortable: false,
        },
        {
          text: "Оценка",         
          value: "courseGrade",
          sortable: true,
        },
        {
          text: "Сертификат",
          value: "courseSertificateAttach",
          sortable: false,
        },
      ],
    };
  },
  components: {
    attachment: AttachmentVue,
  },
  methods: {
    openOrder(item) {
      this.$emit("open-order", item);
    },
    redirectOnCourse(course) {
      window.open(course.courseLink);
    },
  },
};
</script>

<style lang="scss">
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 0.85rem !important;
  font-weight: 500;
}
</style>
