<template>
    <div class="mb-5">
        <p class="text-h5 font-weight-light mt-3">Мои смены</p>
        <v-divider></v-divider>
        <v-row class="mt-7" justify="center" align="center">
            <camps-table 
                :camps="camps"
            ></camps-table>
        </v-row>
    </div>
</template>
<script>
import CampsTable from './CampsTable.vue';

export default {
    name: 'Camps',
    props:{
        camps : Array,
    },
    data: () =>  ({
    }),
    computed: {

    },
    components: {
      'camps-table': CampsTable
    },
    methods: {
        setProcessing(state) {
            this.$emit('set-processing', state);
        },
    },
}
</script>
<style scoped>

</style>