<template>
  <div>
    <v-navigation-drawer
      app
      mobile-breakpoint="sm"
      :permanent="$vuetify.breakpoint.mdAndUp"
      :temporary="$vuetify.breakpoint.smAndDown"
      width="250"
    >
      <v-list-item
        :class="
          $vuetify.breakpoint.mdAndUp
            ? 'with-background theme--dark pt-1 px-2'
            : 'px-2'
        "
      >
        <v-list-item-avatar tile>
          <v-img contain src="../assets/only-logo-white.png"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-button page-title">
            Частые вопросы
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="section in howToDataSections"
          :key="section.id"
          link
          :to="'/howto/' + section.route"
        >
          <v-list-item-icon>
            <v-icon color="#d7b56d">{{
              $route.path === "/howto/" + section.route
                ? "mdi-folder"
                : "mdi-folder-outline"
            }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1 font-weight-light">
              {{ section.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="previousPage" class="mt-3" link @click="toBack">
          <v-list-item-icon>
            <v-icon color="#e31e25">mdi-arrow-left</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1 font-weight-light">
              {{ previousPage == 'SignIn' ? 'На главную' : 'Назад' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-container>
      <howto-item v-if="currentTab" :tab="currentTab"></howto-item>
    </v-container>
  </div>
</template>
<script>

import HowToItem from "../components/HowToItem.vue";
import axios from "axios";

export default {
  name: "HowTo",
  props: {
    prev: String,
    id: String
  },
  data: () => ({
    previousPage: 'SignIn',
    howToDataMap: new Map(),
    howToDataSections: []
  }),
  components: {
    "howto-item": HowToItem,
  },
  computed: {
    currentTab: function() {
      const paramId = this.$route.params.id;
      return this.howToDataMap.get(paramId);
    },
  },
  methods: {
    toBack() {
      this.$router.push({ name: this.previousPage }).catch(() => {});
    },
    showCriticalError(errorTitle, errorText) {
      this.isError = true;
      this.errorTitle = errorTitle;
      this.errorText = errorText;
      this.errorRightButtonText = "Обновить";
      this.errorRightButtonAction = this.reloadPage;
      this.errorLeftButtonText = "Выйти";
      this.errorLeftButtonAction = this.signOut;
    },
    setProcessing(state) {
      this.$emit('set-processing', state);
    },
    async getHowToData() {
      this.setProcessing(true);
      try {
        let response = await axios.get("/api/getHowToData", {
          headers: {
            RequestVerificationToken: this.token,
            "X-Requested-With": "XMLHttpRequest",
          },
        });
        if (response.data.success === false)
          throw new Error("Ошибка загрузки данных страницы помощи");

        this.howToDataSections = response.data;
        
        this.howToDataMap = new Map();

        this.howToDataSections.forEach((htdSection) => {
          this.howToDataMap.set(htdSection.route, htdSection);
        });
      } catch (error) {
        console.error(error);
        this.showCriticalError(
          "Ошибка загрузки",
          "При загрузке данных произошла ошибка. Пожалуйста, обновите страницу. Если ошибка повторяется, обратитесь в службу поддержки."
        );
      } finally {
        this.setProcessing(false);
      }
    },
  },
  async created() {
    if (this.prev)
      this.previousPage = this.prev;

    await this.getHowToData();
    this.$nextTick(() => {
      if (
        this.howToDataSections &&
        this.howToDataSections.length > 0 &&
        !this.$route.params.id
      ) {
        const id = this.howToDataSections[0].route;
        this.$router.push({ params: { id: id } });
      }
    });
  }
};
</script>

<style>
.page-title {
  color: #fff !important;
}
</style>
