<template>
  <v-card  
    flat
    tile 
    outlined 
    :width="$vuetify.breakpoint.mdAndUp ? '85%' : '95%'"
  >
    <v-data-table
      :headers="headers"
      :items="orders"
      disable-pagination
      hide-default-footer
      :loading="ordersTableLoading"
      @click:row="openOrder"
      loading-text="Загрузка заявок..."
    >
      <template v-slot:item.statename="{ item }">
        <v-chip small :color="getStateColor(item.statename)" dark>{{ item.statename }}</v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <div v-if="item.statename === 'Черновик'">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="#ef7d1a"
                class="mx-1"
                size="20"
                v-bind="attrs"
                v-on="on"
                @click.native.stop="changeOrderState(item, 'Новая')"
              >
                mdi-arrow-right-thick
              </v-icon>
            </template>
            <span>Отправить</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="#e31e25"
                size="20"
                class="mx-1"
                v-bind="attrs"
                v-on="on"
                @click.native.stop="deleteOrder(item)"
              >
                mdi-window-close
              </v-icon>
            </template>
            <span>Удалить</span>
          </v-tooltip>
        </div>
        <div v-else-if="item.statename === 'На рассмотрении' && item.course.testLink">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="#7690c9"
              class="mx-1"
              size="20"
              v-bind="attrs"
              v-on="on"
              @click.native.stop="redirectOnTest(item)"
            >
              mdi-arrow-right-thick
            </v-icon>
            </template>
            <span>Перейти к тестированию</span>
          </v-tooltip>
        </div>
        <div v-else-if="item.statename === 'Новая'">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="#af955c"
              class="mx-1"
              size="20"
              v-bind="attrs"
              v-on="on"
              @click.native.stop="changeOrderState(item, 'Черновик')"
            >
              mdi-arrow-left-thick
            </v-icon>
            </template>
            <span>Вернуть на редактирование</span>
          </v-tooltip>
        </div>
        <div v-else>
        </div>
      </template>
      <template v-slot:no-data>
        <span class="font-weight-light">Нет заявок</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "OrdersTable",
  props: {
    orders: Array,
    ordersTableLoading: Boolean
  },
  data() {
    return {
      headers: [
        {
          text: 'Состояние',
          //align: 'start',
          sortable: true,
          //width: this.$vuetify.breakpoint.smAndUp ? '15%' : '100%',
          value: 'statename',
        },
        { 
          text: '№',
          //align: 'end',
          sortable: true,
          //width: this.$vuetify.breakpoint.smAndUp ? '5%' : '100%',
          value: 'number' 
        },
        { 
          text: 'Дата',
          //align: 'center',
          //width: this.$vuetify.breakpoint.smAndUp ? '15%' : '100%',
          value: 'date' 
        },
        { 
          text: 'Курс',
          //align: 'start',
          //width: this.$vuetify.breakpoint.smAndUp ? '35%' : '100%',
          value: `course.fName` 
        },
        { 
          text: 'Результат тестирования',
          width: this.$vuetify.breakpoint.smAndUp ? '15%' : '100%',
          value: 'testResult'
        },
        { 
          text: 'Действия', 
          value: 'actions',
          align: 'right',
          //width: this.$vuetify.breakpoint.smAndUp ? '15%' : '100%',
          sortable: false 
        },
      ]
    }
  },
  methods: {
    getStateColor(statename) {
      if (statename === 'Черновик') return 'grey';
      if (statename === 'Новая') return '#ef7d1a';
      if (statename === 'На рассмотрении') return '#00AEBA'
      if (statename === 'Отклонена') return '#e31e25'
      if (statename === 'Утверждена') return '#d7b56d'
      if (statename === 'Резерв') return '#af955c'
      return 'grey';
    },
    deleteOrder(item) {
      this.$emit('delete-order', item.id);
    },
    openOrder(item) {
      this.$emit('open-order', item);
    },
    redirectOnTest(order){
      window.open(order.course.testLink);
    },
    changeOrderState(order, statename) {
      this.$emit('change-order-state', order, statename);
    },
    reloadData() {

    }
  }
};
</script>

<style lang="scss">
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
  }
  .v-data-table>.v-data-table__wrapper>table>thead>tr>th {
    font-size: 0.85rem !important;
    font-weight: 500;
  }
</style>