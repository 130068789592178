import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import AuthPlugin from './plugins/auth';
import ApiPlugin from './plugins/api';
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';

Vue.config.productionTip = false;

import { VueMaskDirective } from 'v-mask';
Vue.directive('mask', VueMaskDirective);
Vue.use(Viewer);
Vue.use(AuthPlugin);
Vue.use(ApiPlugin);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');
