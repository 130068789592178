<template>
  <div class="mb-5">
    <p class="text-h5 font-weight-light mt-3">Мои достижения</p>
    <v-divider></v-divider>
    <v-row
      class="mt-3" 
      justify="center" 
      align="center"
    >
      <v-btn
        color="#7690c9"
        class="ma-2"
        text
        dark
        @click="addAchievement"
      >
        <v-icon left dark>
          mdi-plus
        </v-icon>
        Добавить достижение
      </v-btn>
    </v-row>

    <div v-if="achievementWins && achievementWins.length > 0" :class="$vuetify.breakpoint.mdAndUp ? 'mx-15' : 'mx-2'">
      <v-icon color="#d7b56d" size="26" class="pb-2">mdi-trophy-variant-outline</v-icon>
      <span class="text-body-1 font-weight-half-medium ml-1">Победы</span>
    </div>

    <v-row v-for="achievement in achievementWins" :key="achievement.id" class="mt-3" justify="center" align="center">
      <achievement-card
        :ref="achievement.id"
        :rules="rules"
        :isEvent="isEvent"
        :achievement="achievement"
        :editingAchievement="editingAchievement"
        :eventCountries="eventCountries"
        :eventRegions="eventRegions"
        v-on:set-processing="setProcessing"
        v-on:set-editing-achievement="setEditingAchievement"
        v-on:save="save"
        v-on:delete-achievement="deleteAchievement"
        v-on:get-achievements="getAchievements"
        v-on:show-snackbar="showSnackbar"
      >
      </achievement-card>
    </v-row>

    <div v-if="achievementGrants && achievementGrants.length > 0" :class="$vuetify.breakpoint.mdAndUp ? 'mx-15 mt-8' : 'mx-2 mt-3'">
      <v-icon color="#00AEBA" size="26" class="pb-2">mdi-diamond-stone</v-icon>
      <span class="text-body-1 font-weight-half-medium ml-1">Премии и стипендии</span>
    </div>

    <v-row v-for="achievement in achievementGrants" :key="achievement.id" class="mt-3" justify="center" align="center">
      <achievement-card
        :ref="achievement.id"
        :rules="rules"
        :isEvent="isEvent"
        :achievement="achievement"
        :editingAchievement="editingAchievement"
        :eventCountries="eventCountries"
        :eventRegions="eventRegions"
        v-on:set-processing="setProcessing"
        v-on:set-editing-achievement="setEditingAchievement"
        v-on:save="save"
        v-on:delete-achievement="deleteAchievement"
        v-on:get-achievements="getAchievements"
        v-on:show-snackbar="showSnackbar"
      >
      </achievement-card>
    </v-row>

    <div v-if="achievementWorks && achievementWorks.length > 0" :class="$vuetify.breakpoint.mdAndUp ? 'mx-15 mt-8' : 'mx-2 mt-3'">
      <v-icon color="#ef7d1a" size="26" class="pb-2">mdi-image-frame</v-icon>
      <span class="text-body-1 font-weight-half-medium ml-1">Примеры работ</span>
    </div>

    <v-row v-for="achievement in achievementWorks" :key="achievement.id" class="mt-3" justify="center" align="center">
      <achievement-card
        :ref="achievement.id"
        :rules="rules"
        :isEvent="isEvent"
        :achievement="achievement"
        :editingAchievement="editingAchievement"
        :eventCountries="eventCountries"
        :eventRegions="eventRegions"
        v-on:set-processing="setProcessing"
        v-on:set-editing-achievement="setEditingAchievement"
        v-on:save="save"
        v-on:delete-achievement="deleteAchievement"
        v-on:get-achievements="getAchievements"
        v-on:show-snackbar="showSnackbar"
      >
      </achievement-card>
    </v-row>

    <div v-if="achievementPerfomaces && achievementPerfomaces.length > 0" :class="$vuetify.breakpoint.mdAndUp ? 'mx-15 mt-8' : 'mx-2 mt-3'">
      <v-icon color="#7690c9" size="26" class="pb-2">mdi-movie-open-outline</v-icon>
      <span class="text-body-1 font-weight-half-medium ml-1">Примеры выступлений</span>
    </div>

    <v-row v-for="achievement in achievementPerfomaces" :key="achievement.id" class="mt-3" justify="center" align="center">
      <achievement-card
        :ref="achievement.id"
        :rules="rules"
        :isEvent="isEvent"
        :achievement="achievement"
        :editingAchievement="editingAchievement"
        :eventCountries="eventCountries"
        :eventRegions="eventRegions"
        v-on:set-processing="setProcessing"
        v-on:set-editing-achievement="setEditingAchievement"
        v-on:save="save"
        v-on:delete-achievement="deleteAchievement"
        v-on:get-achievements="getAchievements"
        v-on:show-snackbar="showSnackbar"
      >
      </achievement-card>
    </v-row>

    <div v-if="achievementRecomendations && achievementRecomendations.length > 0" :class="$vuetify.breakpoint.mdAndUp ? 'mx-15 mt-8' : 'mx-2 mt-3'">
      <v-icon color="#af955c" size="26" class="pb-2">mdi-seal-variant</v-icon>
      <span class="text-body-1 font-weight-half-medium ml-1">Рекомендации</span>
    </div>

    <v-row v-for="achievement in achievementRecomendations" :key="achievement.id" class="mt-3" justify="center" align="center">
      <achievement-card
        :ref="achievement.id"
        :rules="rules"
        :isEvent="isEvent"
        :achievement="achievement"
        :editingAchievement="editingAchievement"
        :eventCountries="eventCountries"
        :eventRegions="eventRegions"
        v-on:set-processing="setProcessing"
        v-on:set-editing-achievement="setEditingAchievement"
        v-on:save="save"
        v-on:delete-achievement="deleteAchievement"
        v-on:get-achievements="getAchievements"
        v-on:show-snackbar="showSnackbar"
      >
      </achievement-card>
    </v-row>

    <div v-if="achievementLetter && achievementLetter.length > 0" :class="$vuetify.breakpoint.mdAndUp ? 'mx-15 mt-8' : 'mx-2 mt-3'">
      <v-icon color="#7e8200" size="26" class="pb-2">mdi-email-edit-outline</v-icon>
      <span class="text-body-1 font-weight-half-medium ml-1">Мотивационное письмо</span>
    </div>

    <v-row v-for="achievement in achievementLetter" :key="achievement.id" class="mt-3" justify="center" align="center">
      <achievement-card
        :ref="achievement.id"
        :rules="rules"
        :isEvent="isEvent"
        :achievement="achievement"
        :editingAchievement="editingAchievement"
        :eventCountries="eventCountries"
        :eventRegions="eventRegions"
        v-on:set-processing="setProcessing"
        v-on:set-editing-achievement="setEditingAchievement"
        v-on:save="save"
        v-on:delete-achievement="deleteAchievement"
        v-on:get-achievements="getAchievements"
        v-on:show-snackbar="showSnackbar"
      >
      </achievement-card>
    </v-row>

    <div v-if="achievementEssay && achievementEssay.length > 0" :class="$vuetify.breakpoint.mdAndUp ? 'mx-15 mt-8' : 'mx-2 mt-3'">
      <v-icon color="#1a8200" size="26" class="pb-2">mdi-lead-pencil</v-icon>
      <span class="text-body-1 font-weight-half-medium ml-1">Эссе</span>
    </div>

    <v-row v-for="achievement in achievementEssay" :key="achievement.id" class="mt-3" justify="center" align="center">
      <achievement-card
        :ref="achievement.id"
        :rules="rules"
        :isEvent="isEvent"
        :achievement="achievement"
        :editingAchievement="editingAchievement"
        :eventCountries="eventCountries"
        :eventRegions="eventRegions"
        v-on:set-processing="setProcessing"
        v-on:set-editing-achievement="setEditingAchievement"
        v-on:save="save"
        v-on:delete-achievement="deleteAchievement"
        v-on:get-achievements="getAchievements"
        v-on:show-snackbar="showSnackbar"
      >
      </achievement-card>
    </v-row>

    <div v-if="achievementCertificates && achievementCertificates.length > 0" :class="$vuetify.breakpoint.mdAndUp ? 'mx-15 mt-8' : 'mx-2 mt-3'">
      <v-icon color="#A61000" size="26" class="pb-2">mdi-file-certificate-outline</v-icon>
      <span class="text-body-1 font-weight-half-medium ml-1">Сертификаты и свидетельства</span>
    </div>

    <v-row v-for="achievement in achievementCertificates" :key="achievement.id" class="mt-3" justify="center" align="center">
      <achievement-card
        :ref="achievement.id"
        :rules="rules"
        :isEvent="isEvent"
        :achievement="achievement"
        :editingAchievement="editingAchievement"
        :eventCountries="eventCountries"
        :eventRegions="eventRegions"
        v-on:set-processing="setProcessing"
        v-on:set-editing-achievement="setEditingAchievement"
        v-on:save="save"
        v-on:delete-achievement="deleteAchievement"
        v-on:get-achievements="getAchievements"
        v-on:show-snackbar="showSnackbar"
      >
      </achievement-card>
    </v-row>    

    <div v-if="achievementOthers && achievementOthers.length > 0" :class="$vuetify.breakpoint.mdAndUp ? 'mx-15 mt-8' : 'mx-2 mt-3'">
      <v-icon color="#e31e25" size="26" class="pb-2">mdi-bullseye-arrow</v-icon>
      <span class="text-body-1 font-weight-half-medium ml-1">Иные достижения</span>
    </div>

    <v-row v-for="achievement in achievementOthers" :key="achievement.id" class="mt-3" justify="center" align="center">
      <achievement-card
        :ref="achievement.id"
        :rules="rules"
        :isEvent="isEvent"
        :achievement="achievement"
        :editingAchievement="editingAchievement"
        :eventCountries="eventCountries"
        :eventRegions="eventRegions"
        v-on:set-processing="setProcessing"
        v-on:set-editing-achievement="setEditingAchievement"
        v-on:save="save"
        v-on:delete-achievement="deleteAchievement"
        v-on:get-achievements="getAchievements"
        v-on:show-snackbar="showSnackbar"
      >
      </achievement-card>
    </v-row>
  </div>
</template>

<script>
  import axios from 'axios';
  import AchievementCard from './AchievementCard.vue';
  
  export default {
    name: "Achievements",
    props: {
      achievements: Array,
      rules: Object,
      isEvent: Function
    },
    data: () => ({
      editingAchievement: null,
      eventCountries: [],
      eventRegions: []
    }),
    computed: {
      achievementWins() {
        if(this.achievements) {
          let achievements = this.achievements.filter(a => a.type === 'Победы').sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          return achievements;
        }
        else {
          return [];
        }
      },
      achievementGrants() {
        if(this.achievements) {
          let achievements = this.achievements.filter(a => a.type === 'Премии и стипендии').sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          return achievements;
        }
        else {
          return [];
        }
      },
      achievementWorks() {
        if(this.achievements) {
          let achievements = this.achievements.filter(a => a.type === 'Примеры работ').sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          return achievements;
        }
        else {
          return [];
        }
      },
      achievementPerfomaces() {
        if(this.achievements) {
          let achievements = this.achievements.filter(a => a.type === 'Примеры выступлений').sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          return achievements;
        }
        else {
          return [];
        }
      },
      achievementRecomendations() {
        if(this.achievements) {
          let achievements = this.achievements.filter(a => a.type === 'Рекомендации').sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          return achievements;
        }
        else {
          return [];
        }
      },
      achievementOthers() {
        if(this.achievements) {
          let achievements = this.achievements.filter(a => a.type === 'Иные достижения').sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          return achievements;
        }
        else {
          return [];
        } 
      },
      achievementEssay() {
        if(this.achievements) {
          let achievements = this.achievements.filter(a => a.type === 'Эссе').sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          return achievements;
        }
        else {
          return [];
        } 
      },
      achievementLetter() {
        if(this.achievements) {
          let achievements = this.achievements.filter(a => a.type === 'Мотивационное письмо').sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          return achievements;
        }
        else {
          return [];
        } 
      },
      achievementCertificates() {
        if(this.achievements) {
          let achievements = this.achievements.filter(a => a.type === 'Сертификаты и свидетельства').sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          return achievements;
        }
        else {
          return [];
        } 
      },
    },
    components: {
      'achievement-card': AchievementCard
    },
    methods: {
      async loadData() {
        this.$emit('set-processing', true);
        this.eventCountries = await this.$api.getCountries();
        this.eventRegions = await this.$api.getRegions();
        this.$emit('set-processing', false);
      },
      collapseAllPanels() {
        if(this.achievements && this.achievements.length > 0) {
          this.achievements.forEach(a => {
            if(this.$refs[a.id] && this.$refs[a.id].length > 0) this.$refs[a.id][0].panel = null;
          });
        }
      },
      showSnackbar(success, text) {
        this.$emit('show-snackbar', success, text);
      },
      deleteAchievement(id) {
        this.$emit('delete-achievement', id);
      },
      setProcessing(state) {
        this.$emit('set-processing', state);
      },
      async setEditingAchievement(id) {
        /*if(id) {
          const target =  document.getElementsByClassName(`achievement-${id}`)[0];
          const options = {
            duration: 400,
            offset: 250,
            easing: 'easeInOutCubic',
          }
          setTimeout(() => {
            this.$vuetify.goTo(target, options);
          }, 500);
        }*/
        this.editingAchievement = id;
      },
      addAchievement() {
        this.$emit('add-achievement');
      },
      save(data) {
        this.$emit('save', data, 'changeForm');
      },
      getAchievements(){
        this.$emit('get-achievements');
      },
    },
    watch: {
      achievements: function(newVal, oldVal) {
        if(newVal.length != oldVal.length) {
          this.editingAchievement = null;
        }
      }
    },    
    created() {
      this.loadData();
    },
    deactivated() {
      this.collapseAllPanels();
    }
  };
</script>