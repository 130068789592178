<template>
  <v-card 
      :class="$vuetify.breakpoint.mdAndUp ? `document-${document.id} px-1` : `document-${document.id} mx-3`" 
      flat
      :loading="isLoading"
      tile 
      outlined 
      :width="$vuetify.breakpoint.mdAndUp ? '80%' : '95%'"
    >
      <v-expansion-panels flat accordion v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header class="pl-0 pr-2 py-0 mx-0">
            <v-app-bar flat dense color="rgba(0, 0, 0, 0)" class="pr-0 mr-0">
              <v-icon size="18" left  color="#00AEBA" >mdi-{{ document.doc_type_mdi }}</v-icon>
              <v-toolbar-title 
              :style="`max-width: ${titleMaxWidth}px !important`" 
              :class="panel === 0 ? 'text-body-1 font-weight-half-medium' : 'text-body-1'">
                {{document.type ? document.type : 'Не указано'}}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                 v-if="!document.isReadOnly"
                :color="editingDocument === document.id ? '#e31e25' : '#7690c9'"
                text 
                dark 
                x-small 
                :disabled="editingDocument && editingDocument !== document.id" 
                @click.native.stop="documentEditButton(document.id, document.isReadOnly)"
              >
                <v-icon left dark>{{editingDocument === document.id ? 'mdi-window-close' : 'mdi-pencil'}}</v-icon>
                {{editingDocument === document.id ? 'Отмена' : 'Редактировать'}}
              </v-btn>
            </v-app-bar>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="pl-4 mb-4"
              v-if="editingDocument !== document.id"
              :document="document"
              :isEvent="isEvent"
            >
            <span>{{document.name}}</span>
            <attachment class="mt-4" :attach="document.attach" 
            :type="document.attach.name"
            ></attachment>
            </div>
            <document-form 
              v-else
              :document="document" 
              :rules="rules"
              :isNew="false"
              v-on:delete-document="deleteDocument"
              v-on:set-processing="setProcessing"
              v-on:show-snackbar="showSnackbar"
              v-on:save-document="saveDocument"
               @set-loading="(state) => isLoading == state"
            ></document-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </template>
  
  <script>
  import DocumentForm from './DocumentForm.vue';
  import AttachmentVue from './Attachment.vue'
  
  export default {
      name: 'DocumentCard',
      props:{
          rules: Object,
          document: Object,
          editingDocument: null,
      },
      data() {
        return {
          panel: null,
          isLoading: false
        }
      },
      computed: {
        titleMaxWidth() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 90;
          case "sm":
            return 360;
          case "md":
            return 360;
          case "lg":
            return 700;
          case "xl":
            return 1000;
        }
      },
      },
      components :{
        'document-form': DocumentForm,
        'attachment': AttachmentVue,
      },
        methods: {
          deleteDocument(id) {
            this.$emit('delete-document', id);
          },
          documentEditButton(documentId, isReadOnly) {
            if(isReadOnly) {
              this.$emit('show-snackbar', false, 'Данный документ нельзя редактировать, так как он приложен к заявке.');
              return;
            }
            if(this.editingDocument) {
              this.$emit('set-editing-document', null);
            }
            else {
              this.panel = 0;
              this.getDocuments();
              this.$emit('set-editing-document', documentId);
            }
          },
          saveDocument(data) {
            this.$emit('save-document', data);
          },
          getDocuments(){
            this.$emit('get-documents');
          },
          setProcessing(state) {
            this.$emit('set-processing', state);
          },
          showSnackbar(success, text) {
            this.$emit('show-snackbar', success, text);
      },
    },
  
    watch: {
      panel: {
        handler(val, oldVal) {
          if(oldVal === 0 && (val === undefined || val === null)) {
            this.$emit('set-editing-document', null);
          }
          else if(val === 0) {
            const target =  document.getElementsByClassName(`document-${this.document.id}`)[0];
            const options = {
              duration: 400,
              offset: 250,
              easing: 'easeInOutCubic',
            }
            setTimeout(() => {
              this.$vuetify.goTo(target, options);
            }, 200);
          }
        }
      }
    }
  }
  </script>
  <style scoped>
  
  </style>