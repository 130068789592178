<template>
  <v-row justify="center">
    <v-dialog v-model="isVisible" persistent :max-width="dialogMaxWidth">
      <v-card>
        <v-card-title class="headline">
          {{ title }}
        </v-card-title>
        <v-card-text v-html="text"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="leftButtonText"
            color="#e31e25"
            text
            @click="leftButtonAction"
          >
            {{ leftButtonText }}
          </v-btn>
          <v-btn
            v-if="rightButtonText"
            color="#d7b56d"
            text
            @click="rightButtonAction"
          >
            {{ rightButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "CustomModalDialog",
  props: {
    isVisible: false,
    title: String,
    text: String,
    rightButtonText: String,
    leftButtonText: String,
    rightButtonAction: Function,
    leftButtonAction: Function,
  },
  computed: {
    dialogMaxWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 290;
        case "sm":
          return 310;
        case "md":
          return 400;
        case "lg":
          return 600;
        case "xl":
          return 600;
      }
    },
  },
};
</script>