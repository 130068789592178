<template>
  <v-card
    flat
    tile
    outlined
    :width="$vuetify.breakpoint.mdAndUp ? '85%' : '95%'"
  >
    <v-data-table
      :headers="headers"
      :items="camps"
      disable-pagination
      hide-default-footer
      :loading="campsTableLoading"
      loading-text="Загрузка смен..."
    >
      <template v-slot:no-data>
        <span class="font-weight-light">Нет смен</span>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>

export default {
  name: "CampsTable",
  props: {
    camps: Array,
    campsTableLoading: Boolean,
  },
  data() {
    return {
      headers: [
        {
          text: "Смена",         
          value: "campshiftName",
          align: "center",
          sortable: true,
        },
        {
          text: "Начало",         
          value: "campshiftBDate",
          align: "center",
          sortable: true,
        },
        {
          text: "Окончание",         
          value: "campshiftEDate",
          align: "center",
          sortable: true,
        },
        {
          text: "Должность",
          value: "positionName",
          align: "start",
          sortable: true,
        },
      ],
    };
  },
  components: {

  },
  methods: {

  },
};
</script>

<style lang="scss">
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 0.85rem !important;
  font-weight: 500;
}
</style>
