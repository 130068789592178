<template>
  <v-form class="mx-5" v-model="isDocumentValid">
    <v-row class="mt-4" justify="center" align="center">
      <!-- <v-col cols="12" sm="6" md="12" lg="2" xl="3">
        <v-select
          v-model="documentForEdit.type"
          label="Тип документа"
          hint="Укажите тип документа"
          color="#af955c"
          dense
          :rules="[rules.required]"
          :error="!documentForEdit.type"
          required
        ></v-select>
      </v-col> -->
      <!-- <v-col cols="12" sm="12" md="12" lg="10" xl="9">-->
    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-text-field
          v-model="documentForEdit.name"
          label="Название"
          hint="Введите название"
          color="#af955c"
          dense        
          clearable
          required
          :rules="[rules.required]"
          :error="!documentForEdit.name"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <!-- :type="document.type" -->
    <attachment
      :attach="attachment" 
    >
    </attachment>

    <v-row justify="center" align="center">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-file-input
          show-size
          clearable
          counter
          color="#af955c"
          accept="image/png, image/jpeg, .pdf, .PDF"
          dense
          label="Вложение"
          :placeholder="!documentForEdit.attach && document.attach ? document.attach.name : ''"
          :required="isNew"
          :rules="isNew ? [rules.required, rules.file] : [rules.file]"
          :error="isNew ? !documentForEdit.attach : false"
          v-on:change="onFileChanged"
        ></v-file-input>
      </v-col>
    </v-row>
    <v-row
      v-if="!isNew"
      class="mt-4"
      justify="center"
      align="center"
    >
      <v-btn
        v-if="!isNew"
        color="#e31e25"
        class="ma-2"
        text
        dark
        small
        @click="deleteDocument"
      >
        <v-icon left dark> mdi-window-close </v-icon>
        Удалить документ
      </v-btn>
    </v-row>
    <v-row class="pt-3 pb-1" justify="center" align="center">
      <v-btn  
        color="#af955c"
        text
        :disabled="!isDocumentValid" 
        :dark="isDocumentValid"
        @click="saveDocument"
      >
        <v-icon left dark>mdi-content-save-outline</v-icon>
        Сохранить
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import axios from "axios";
import AttachmentVue from "./Attachment.vue";

export default {
  name: "DocumentDorm",
  props: {
    isNew: Boolean,
    document: Object,
    rules: Object,
  },
  data() {
    return {
      attachment: null,
      isDocumentValid: false,
      documentForEdit: {},
    };
  },
  components: {
    attachment: AttachmentVue,
  },
  computed: {
    documentStringify() {
      return JSON.stringify(this.document);
    },
    documentForEditStringify() {
      return JSON.stringify(this.documentForEdit);
    },
  },
  methods: {
    async onFileChanged(file) {
      this.documentForEdit.attach = file;
      await this.$nextTick();
      this.getAttachment();
    },
    convertToBytes(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    setDocumentForEdit() {
      if(this.document) {
        this.documentForEdit = JSON.parse(JSON.stringify(this.document));
        this.documentForEdit.attach = null;
      }
    },
    async getAttachment() {
      if (this.documentForEdit.attach) {
        const file = this.documentForEdit.attach;
        let bytes = await this.convertToBytes(file).catch((e) => Error(e));
        if (bytes instanceof Error) {
          this.$emit(
            "show-snackbar",
            false,
            "Ошибка загрузки файла, пожалуйста, попробуйте еще раз."
          );
          return;
        }
        let attach = {
          name: file.name,
          data: bytes.replace(/^data:.+;base64,/, ""),
        };
        this.attachment = attach;
      } else {
        this.attachment = this.document.attach;
      }
    },
    saveDocument() {
      this.$emit('save-document', this.documentForEdit);
    },
    deleteDocument() {
      this.$emit('delete-document', this.document.id);
    },
  },
  watch: {
    documentStringify: {
      handler(val, oldVal) {
        this.setDocumentForEdit();
      },
    },
    documentForEditStringify: {
      handler(val, oldVal) {
        // const newDocument = JSON.parse(val);
        // const oldDocument = JSON.parse(oldVal);
      },
    } 
  },
    created() {
    this.setDocumentForEdit();
    this.getAttachment();
  },
};
</script>
<style scoped></style>
