<template>
    <div class="mb-5">
        <p class="text-h5 font-weight-light mt-3">Мои документы</p>
        <v-divider></v-divider>
        <v-row
        class="mt-3" 
        justify="center" 
        align="center"
        >
            <v-btn
            color="#7690c9"
            class="ma-2"
            text
            dark
            @click="addDocument"
            >
                <v-icon left dark>
                mdi-plus
                </v-icon>
                Добавить документ
            </v-btn>
        </v-row>
        <v-row v-for="document in documents" :key="document.id" class="mt-3" justify="center" align="center">
            <document-card
                :ref="document.id"
                :rules="rules"
                :document="document"
                :editingDocument="editingDocument"
                v-on:set-processing="setProcessing"
                v-on:set-editing-document="setEditingDocumnt"
                v-on:save-document="save"
                v-on:get-documents="getDocuments"
                v-on:delete-document="deleteDocument"
                v-on:show-snackbar="showSnackbar"
            />
        </v-row>
    </div>
</template>
<script>
  import DocumentCard from './DocumentCard.vue';

export default {
    name: 'Documents',
    props:{
        documents: Array,
        rules: Object,
    },
    data: () =>  ({
        editingDocument: null,
    }),
    computed: {

    },
    components: {
      'document-card': DocumentCard
    },
    methods: {
        collapseAllPanels() {
            if(this.documents && this.documents.length > 0) {
                this.documents.forEach(d => {
                    if(this.$refs[d.id] && this.$refs[d.id].length > 0) this.$refs[d.id][0].panel = null;
                });
            }
        },
        setEditingDocumnt(id){
            this.editingDocument = id;
        },
        showSnackbar(success, text) {
            this.$emit('show-snackbar', success, text);
        },
        deleteDocument(id) {
            this.$emit('delete-document', id);
        },
        setProcessing(state) {
            this.$emit('set-processing', state);
        },
        async setEditingDocument(id) {
            this.editingDocument = id;           
        },
        addDocument() {
            this.$emit('add-document');
        },
        save(data) {
            this.$emit('save', data, 'changeForm');
        },
        getDocuments(){        
            this.$emit('get-documents');
        }
    },
    watch: {
      documents: function(newVal, oldVal) {
        if(newVal.length != oldVal.length) {
          this.editingAchievement = null;
        }
      }
    },      
    deactivated() {
      this.collapseAllPanels();
    }
}
</script>
<style scoped>

</style>
