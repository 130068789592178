<template>
  <div class="pl-4 mb-4">
    <!--<div v-if="!isEvent(achievement.type)">
      <span class="text-body-1 font-weight-half-medium">
        Тип:
      </span>
      <span class="text-body-1">
        {{achievement.type ? achievement.type : 'Не указан'}}
      </span>
    </div>-->
    <div v-if="isEvent(achievement.type)">
      <span class="text-body-1 font-weight-half-medium">
        Вид мероприятия:
      </span>
      <span class="text-body-1">
        {{achievement.eventKind ? achievement.eventKind : 'Не указан'}}
      </span>
    </div>
    <div v-if="isEvent(achievement.type)">
      <span class="text-body-1 font-weight-half-medium">
        Уровень мероприятия:
      </span>
      <span class="text-body-1">
        {{achievement.eventType ? achievement.eventType : 'Не указан'}}
      </span>
    </div>
    <div v-if="isEvent(achievement.type)">
      <span class="text-body-1 font-weight-half-medium">
        Год проведения:
      </span>
      <span class="text-body-1">
        {{achievement.year ? achievement.year : 'Не указан'}}
      </span>
    </div>
    <div v-if="isEvent(achievement.type)">
      <span class="text-body-1 font-weight-half-medium">
        Место проведения:
      </span> 
      <span class="text-body-1">
        {{getEventPlace(achievement)}}
      </span>
    </div>
    <div v-if="isEvent(achievement.type)">
      <span class="text-body-1 font-weight-half-medium">
        Результат:
      </span> 
      <span class="text-body-1">
        {{achievement.eventResult ? achievement.eventResult : 'Не указан'}}
      </span>
    </div>
    <div v-if="isEvent(achievement.type)">
      <span class="text-body-1 font-weight-half-medium">
        Балл/Место:
      </span> 
      <span class="text-body-1">
        {{achievement.eventResultValue ? achievement.eventResultValue : 'Не указан'}}
      </span>
    </div>
    <div v-if="achievement.type === 'Примеры выступлений'">
      <span class="text-body-1 font-weight-half-medium">
        Ссылка:
      </span>
      <a 
        v-if="achievement.perfomanceLink"
        class="text-body-1 font-weight-half-medium"
        :href="`https://${achievement.perfomanceLink}`"
        target="_blank"
      >
        Нажмите для перехода
      </a>
      <span v-else class="text-body-1">
        {{achievement.perfomanceLink ? achievement.perfomanceLink : 'Не указана'}}
      </span>
    </div>
    <attachment :attach="achievement.attach" :type="achievement.type"></attachment>
  </div>
</template>

<script>
import AttachmentVue from './Attachment.vue';
export default {
  name: 'AchievementFields',
  props: {
    achievement: Object,
    isEvent: Function
  },
  components: {
    'attachment': AttachmentVue
  },
  methods: {
    getEventPlace(achievement) {
      const country = achievement.eventCountry && achievement.eventCountry.name ? achievement.eventCountry.name + ', ' : 'Не указана, ';
      const region = achievement.eventRegion && achievement.eventRegion.name ? achievement.eventRegion.name + ', ' : '';
      const city = achievement.eventCity ? ' г. ' + achievement.eventCity : '';

      return country + region + city;
    },
  }
}
</script>