<template>
  <v-row justify="center" v-if="isVisible">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="1100"
      persistent
    >
      <v-card outlined flat>
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h6 font-weight-half-medium pl-0">
            <v-icon left color="#e31e25" size="25" class="pb-1">mdi-file-document-outline</v-icon>
            <span>{{document.name}}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#e31e25" text dark x-small @click="close">
            <v-icon left dark>mdi-window-close</v-icon>
            Закрыть
          </v-btn>
        </v-app-bar>
        <v-divider />
        <div :style="{ padding: '20px' }">
          <attachment :attach="document.attach" :type="document.attach.name" />
        </div>
        <div v-if="isDocumentForAdd" class="mx-4 pt-3 pb-5 text-center">
          <v-btn
            color="#e31e25"
            class="ma-2"
            small
            text
            @click="$emit('select-document', document)"
          >
            <v-icon left dark>mdi-check</v-icon>
            Приложить документ
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AttachmentVue from './Attachment.vue'
export default {
  name: 'OrderDocumentPreview',
  components :{
    'attachment': AttachmentVue,
  },
  props: {
    isVisible: Boolean,
    document: Object,
    selectedDocuments: Array
  },
  computed: {
    isDocumentForAdd() {
      return this.selectedDocuments.filter(a => a.id === this.document.id).length === 0;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>