<template>
  <v-form class="mx-5" v-model="isAchievementValid">
    <v-row class="mt-4" justify="center" align="center">
      <v-col cols="12" sm="6" md="12" lg="2" xl="3">
        <v-select
          v-model="achievementForEdit.type"
          label="Тип достижения"
          hint="Укажите тип достижения"
          color="#af955c"
          dense
          :items="achievementsTypes"
          :rules="[rules.required]"
          :error="!achievementForEdit.type"
          required
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="10" xl="9">
        <v-text-field
          v-model="achievementForEdit.name"
          label="Наименование"
          hint="Введите наименование"
          color="#af955c"
          dense
          :rules="[rules.required]"
          clearable
          required
          :error="!achievementForEdit.name"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="isEvent(achievementForEdit.type)" justify="center" align="center">
      <v-col cols="12" sm="6" md="6" lg="4" xl="4">
        <v-select
          v-model="achievementForEdit.eventKind"
          label="Вид мероприятия"
          hint="Укажите вид м/п"
          color="#af955c"
          dense
          :items="eventKinds"
          :rules="[rules.required]"
          required
          :error="!achievementForEdit.eventKind"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="4" xl="4">
        <v-select
          v-model="achievementForEdit.eventType"
          label="Уровень мероприятия"
          hint="Укажите уровень м/п"
          color="#af955c"
          dense
          :items="eventTypes"
          :rules="[rules.required]"
          required
          :error="!achievementForEdit.eventType"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="4" xl="4">
        <v-autocomplete
          v-model="achievementForEdit.year"
          :items="eventYears"
          label="Год проведения"
          hint="Введите год м/п"
          color="#af955c"
          dense
          required
          :rules="[rules.required]"
          :error="!achievementForEdit.year"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="isEvent(achievementForEdit.type)" justify="center" align="center">
      <v-col cols="12" sm="6" md="6" lg="4" xl="4">
        <v-autocomplete
          v-model="achievementForEdit.eventCountry"
          label="Страна провередния"
          hint="Укажите страну"
          color="#af955c"
          dense
          :loading="isCountryCompliteLoading"
          :items="eventCountries"
          item-text="name"
          return-object
          :rules="[rules.required]"
          required
          :error="!achievementForEdit.eventCountry.id"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="4" xl="4">
        <v-autocomplete
          v-model="achievementForEdit.eventRegion"
          label="Регион"
          hint="Укажите регион"
          color="#af955c"
          dense
          :loading="isRegionCompleteLoading"
          :items="eventRegions"
          item-text="name"
          return-object
          :rules="isRegionRequierd ? [rules.required] : []"
          :required="isRegionRequierd"
          :error="isRegionRequierd ? !achievementForEdit.eventRegion.id : false"
          :disabled="!isRegionRequierd"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="4" xl="4">
        <v-text-field
          v-model="achievementForEdit.eventCity"
          label="Город проведения"
          hint="Введите город"
          color="#af955c"
          dense
          clearable
          required
          :rules="[rules.required]"
          :error="!achievementForEdit.eventCity"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="isEvent(achievementForEdit.type)" justify="start" align="center">
      <v-col cols="12" sm="6" md="6" lg="4" xl="4">
        <v-select
          v-model="achievementForEdit.eventResult"
          label="Результат"
          hint="Укажите результат"
          color="#af955c"
          dense
          :items="eventResults"
          item-text="name"
          item-value="id"
          :rules="[rules.required]"
          required
          :error="!achievementForEdit.eventResult"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="3" xl="2">
        <v-text-field
          v-model="achievementForEdit.eventResultValue"
          label="Балл/Место"
          hint="Введите балл/место"
          color="#af955c"
          dense
          clearable
          type="number"
          required
          :rules="[rules.required]"
          :error="!achievementForEdit.eventResultValue"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" v-if="achievementForEdit.type === 'Примеры выступлений'">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-text-field
          v-model="achievementForEdit.perfomanceLink"
          label="Ссылка"
          hint="Укажите ссылку"
          color="#af955c"
          clearable
          dense
          required
          :rules="[rules.required]"
          :error="!achievementForEdit.perfomanceLink"
        ></v-text-field>
      </v-col>
    </v-row>

    <attachment
      v-if="achievementForEdit.type !== 'Примеры выступлений'"
      :attach="attachment" 
      :type="achievement.type"
    >
    </attachment>

    <v-row justify="center" align="center" v-if="achievementForEdit.type !== 'Примеры выступлений'">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-file-input
          show-size
          clearable
          counter
          color="#af955c"
          accept="image/png, image/jpeg, .pdf, .PDF"
          dense
          label="Вложение"
          :placeholder="!achievementForEdit.attach && achievement.attach ? achievement.attach.name : ''"
          :required="isNew"
          :rules="isNew ? [rules.required, rules.file] : [rules.file]"
          :error="isNew ? !achievementForEdit.attach : false"
          v-on:change="onFileChanged"
        ></v-file-input>
      </v-col>
    </v-row>
    <v-row
      v-if="!isNew"
      class="mt-4"
      justify="center"
      align="center"
    >
      <v-btn
        color="#e31e25"
        class="ma-2"
        text
        dark
        small
        @click="deleteButton"
      >
        <v-icon left dark> mdi-window-close </v-icon>
        Удалить достижение
      </v-btn>
    </v-row>
    <v-row class="pt-3 pb-1" justify="center" align="center">
      <v-btn  
        color="#af955c"
        text
        :disabled="!isAchievementValid" 
        :dark="isAchievementValid"
        @click="save"
      >
        <v-icon left dark>mdi-content-save-outline</v-icon>
        Сохранить
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import AttachmentVue from './Attachment.vue';

export default {
  name: 'AchievementForm',
  props: {
    achievement: Object,
    isEvent: Function,
    rules: Object,
    eventCountries: Array,
    eventRegions: Array,
    isNew: Boolean
  },
  data() {
    return {
      attachment: null,
      isCountryCompliteLoading: false,
      isRegionCompleteLoading: false,
      isAchievementValid: false,
      achievementForEdit: {},
      eventYears: [],
      achievementsTypes: ['Победы', 'Премии и стипендии', 'Примеры работ', 'Примеры выступлений', 'Рекомендации', 'Мотивационное письмо',
      'Эссе', 'Сертификаты и свидетельства', 'Иные достижения'],
      eventKinds: ['Конкурс', 'Олимпиада', 'Соревнование', 'Турнир', 'Фестиваль'],
      eventTypes: ['Муниципальный', 'Региональный', 'Всероссийский', 'Международный'],
      eventResults: ['Победитель', 'Призер', 'Участник']
    }
  },
  components: {
    'attachment': AttachmentVue
  },
  computed: {
    achievementStringify() {
      return JSON.stringify(this.achievement);
    },
    achievementForEditStringify() {
      return JSON.stringify(this.achievementForEdit);
    },
    isRegionRequierd() {
      return this.achievementForEdit.eventCountry && this.achievementForEdit.eventCountry.name === 'Россия';
    }
  },
  methods: {
    convertToBytes(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    async getAttachment() {
      if(this.achievementForEdit.attach) {
        const file = this.achievementForEdit.attach;
        let bytes = await this.convertToBytes(file).catch(e => Error(e));
        if(bytes instanceof Error) {
          console.log(bytes.message);
          this.$emit('show-snackbar', false, 'Ошибка загрузки файла, пожалуйста, попробуйте еще раз.');
          return;
        }
        let attach = {
          name: file.name,
          data: bytes.replace(/^data:.+;base64,/, '')
        };
        this.attachment = attach;
      }
      else {
        this.attachment = this.achievement.attach;
      }
    },
    setDefaultCountry() {
      if(this.achievementForEdit.eventCountry.id === null && this.eventCountries.length > 0) {
        let defaultCountry = this.eventCountries.find(country => {
          return country.name === 'Россия'
        });
        if(defaultCountry) {
          this.achievementForEdit.eventCountry.id = defaultCountry.id;
          this.achievementForEdit.eventCountry.name = defaultCountry.name;
        }
      }
    },
    calculateEventYears() {
      let years = [];
      const currentYear = new Date().getFullYear();
      for(let year = currentYear; year >= 2000; year--) {
        years.push(year.toString());
      }
      this.eventYears = years;
    },
    async onFileChanged(file) {
      this.achievementForEdit.attach = file;
      await this.$nextTick();
      this.getAttachment();
    },
    setAchievementForEdit() {
      if(this.achievement) {
        this.achievementForEdit = JSON.parse(JSON.stringify(this.achievement));
        this.achievementForEdit.attach = null;
      }
    },
    save() {
      this.$emit('save', this.achievementForEdit);
    },
    deleteButton() {
      this.$emit('delete-achievement', this.achievement.id);
    }
  },
  watch: {
    achievementStringify: {
      handler(val, oldVal) {
        this.setAchievementForEdit();
      },
    },
    achievementForEditStringify: {
      handler(val, oldVal) {
        const newAchievement = JSON.parse(val);
        const oldAchievement = JSON.parse(oldVal);
        if(newAchievement.type !== "Победы") {
          this.achievementForEdit.eventCity = null;
          this.achievementForEdit.eventCountry = {id: null, name: null};
          this.achievementForEdit.eventRegion = {id: null, name: null};
          this.achievementForEdit.eventResult = null;
          this.achievementForEdit.eventResultValue = null;
          this.achievementForEdit.eventKind = null;
          this.achievementForEdit.eventType = null;
          this.achievementForEdit.year = null;
        }
        else {
          this.setDefaultCountry();
        }

        if(newAchievement.eventCountry.name !== 'Россия') {
          this.achievementForEdit.eventRegion = {id: null, name: null};
        }
      },
    }
  },
  created() {
    this.calculateEventYears();
    this.setAchievementForEdit();
    this.getAttachment();
  }
}
</script>