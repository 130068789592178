<template>
  <div>
    <div class="text-center pb-4">
      <p v-if="selectedAchievements.length === 0" class="font-weight-light">
        Достижения не приложены
      </p>
      <v-chip
        v-for="achievement in selectedAchievementsSorted"
        :style="{ maxWidth: '350px' }"
        :key="achievement.id"
        small
        :close="order.statename === 'Черновик'"
        outlined
        :color="getAchievementColor(achievement.type)"
        class="ma-2"
        @click:close="removeAchievement(achievement)"
        @click="onAchievementClicked(achievement)"
      >
        <v-icon size="18" left>
          {{getAchievementIcon(achievement.type)}}
        </v-icon>
        <span :title="achievement.name" class="chip-text">{{achievement.name}}</span>
      </v-chip>
    </div>
    <div v-if="order.statename === 'Черновик'" class="text-center">
      <v-autocomplete
        v-model="achievementSearch"
        :disabled="ordersTableLoading"
        label="Достижение"
        hint="Выберите достижение"
        placeholder="Выберите достижение из списка"
        color="#af955c"
        no-data-text="Нет доступных достижений"
        dense
        :items="availableAchievements"
        item-text="name"
        return-object
        @change="onAchievementClicked"
      >
        <template v-slot:item="data">
            <v-list-item-avatar>
              <v-icon :color="getAchievementColor(data.item.type)">
                {{getAchievementIcon(data.item.type)}}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
            </v-list-item-content>
        </template>
        <template v-slot:append-outer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="#af955c"
                @click="createNewAchievement"
                v-text="'mdi-plus-circle-outline'"
                v-bind="attrs"
                v-on="on"
              ></v-icon>
            </template>
            <span>Создать достижение</span>
          </v-tooltip>
        </template>
      </v-autocomplete>
    </div>
    <achievement-preview 
      :isVisible="isAchievmentPreviewOpened" 
      :achievement="openedAchievement"
      :isEvent="isEvent"
      :getAchievementIcon="getAchievementIcon"
      :getAchievementColor="getAchievementColor"
      :selectedAchievements="selectedAchievements"
      v-on:select-achievement="selectAchievement"
      v-on:close="closeAchievementPreview"
    ></achievement-preview>
  </div>
</template>

<script>
import OrderAchievementPreviewVue from './OrderAchievementPreview.vue';
export default {
  name: "OrderAchievements",
  props: {
    order: Object,
    achievements: Array,
    isEvent: Function,
    ordersTableLoading: Boolean
  },
  data() {
    return {
      selectedAchievements: [],
      achievementSearch: null,
      isAchievmentPreviewOpened: false,
      openedAchievement: null
    }
  },
  components: {
    'achievement-preview': OrderAchievementPreviewVue
  },
  computed: {
    availableAchievements() {
      let sortBy = [{
        prop:'typeDirection',
        direction: 1
      },{
        prop:'name',
        direction: 1
      }];
      return this.achievements.filter(achievement => {
        return this.selectedAchievements.filter(selected => selected.id === achievement.id).length === 0;
      }).sort((a, b) => {
        let i = 0, result = 0;
        while(i < sortBy.length && result === 0) {
          result = sortBy[i].direction*(a[sortBy[i].prop].toString() < b[sortBy[i].prop].toString() ? -1 : (a[sortBy[i].prop].toString() > b[sortBy[i].prop].toString() ? 1 : 0));
          i++;
        }
        return result;
      });
    },
    selectedAchievementsSorted() {
      let sortBy = [{
        prop:'typeDirection',
        direction: 1
      },{
        prop:'name',
        direction: 1
      }];
      let resultArray = this.selectedAchievements.sort((a, b) => {
        let i = 0, result = 0;
        while(i < sortBy.length && result === 0) {
          result = sortBy[i].direction*(a[sortBy[i].prop].toString() < b[sortBy[i].prop].toString() ? -1 : (a[sortBy[i].prop].toString() > b[sortBy[i].prop].toString() ? 1 : 0));
          i++;
        }
        return result;
      });
      return resultArray;
    },
    orderStringify() {
      return JSON.stringify(this.order);
    }
  },
  methods: {
    async onAchievementClicked(achievement) {
      let selectedAchievement = this.achievements.filter(a => a.id === achievement.id);
      if(selectedAchievement && selectedAchievement.length > 0) {
        await this.$nextTick();
        this.achievementSearch = null;
        this.openedAchievement = selectedAchievement[0];
        this.isAchievmentPreviewOpened = true;
      }
    },
    closeAchievementPreview() {
      this.openedAchievement = null;
      this.isAchievmentPreviewOpened = false;
    },
    createNewAchievement() {
      this.$emit('add-achievement');
    },
    getAchievementColor(type) {
      if(type === 'Победы') return '#d7b56d';
      if(type === 'Премии и стипендии') return '#00AEBA';
      if(type === 'Примеры работ') return '#ef7d1a';
      if(type === 'Примеры выступлений') return '#7690c9';
      if(type === 'Рекомендации') return '#af955c';
      if(type === 'Эссе') return '#1a8200';
      if(type === 'Мотивационное письмо') return '#7e8200';
      if(type === 'Сертификаты и свидетельства') return '#A61000';
      if(type === 'Иные достижения') return '#e31e25';
    },
    getAchievementIcon(type) {
      if(type === 'Победы') return 'mdi-trophy-variant-outline';
      if(type === 'Премии и стипендии') return 'mdi-diamond-stone';
      if(type === 'Примеры работ') return 'mdi-image-frame';
      if(type === 'Примеры выступлений') return 'mdi-movie-open-outline';
      if(type === 'Рекомендации') return 'mdi-seal-variant';
      if(type === 'Эссе') return 'mdi-lead-pencil';
      if(type === 'Мотивационное письмо') return 'mdi-email-edit-outline';
      if(type === 'Сертификаты и свидетельства') return 'mdi-file-certificate-outline';
      if(type === 'Иные достижения') return 'mdi-bullseye-arrow';
    },
    async selectAchievement(achievement) {
      let selected = {
        id: achievement.id,
        name: achievement.name,
        type: achievement.type,
        orderId: this.order.id
      };
      await this.$emit('select-achievement', selected);
      this.achievementSearch = null;
      this.isAchievmentPreviewOpened = false;
      this.openedAchievement = null;
      //this.selectedAchievements.push(selected);
    },
    async removeAchievement(achievement) {
      if(!this.ordersTableLoading) {
        await this.$emit('remove-achievement', achievement.id, this.order.id);
        /*const chipsToRemove = this.selectedAchievements.filter(a => a.id === achievement.id);
        chipsToRemove.forEach(chip => this.selectedAchievements.splice(this.selectedAchievements.indexOf(chip), 1));*/
      }
    },
    setSelectedAchievements() {
      this.selectedAchievements = [ ...this.order.achievements ];
    }
  },
  watch: {
    orderStringify: {
      handler(val, oldVal) {
        this.setSelectedAchievements();
      }
    }
  },
  created() {
    this.setSelectedAchievements();
  }
};
</script>

<style lang="scss">
  .chip-text {
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>