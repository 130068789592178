import axios from 'axios';

export default {
  async install(Vue, options) {
    Vue.prototype.$api = {};

    Vue.prototype.$api.getCountries = async function () {
      try {
        let response = await axios.get("/api/getCountries", {
          headers: {
            RequestVerificationToken: this.token,
            "X-Requested-With": "XMLHttpRequest",
          },
        });
        return response.data;
      }
      catch (error) {
        console.error(error);
        return [];
      }
    }

    Vue.prototype.$api.getRegions = async function () {
      try {
        let response = await axios.get("/api/getRegions", {
          headers: {
            RequestVerificationToken: this.token,
            "X-Requested-With": "XMLHttpRequest",
          },
        });
        return response.data;
      }
      catch (error) {
        console.error(error);
        return [];
      }
    }
  }
}