<template>
  <div class="mb-5">
    <p class="text-h5 font-weight-light mt-3">{{ tab.name }}</p>
    <v-divider></v-divider>
    <v-expansion-panels>
      <v-expansion-panel v-for="item in tab.items" v-bind:key="item.id">
        <v-expansion-panel-header>
          <span class="text-body-1 font-weight-half-medium">
            {{ item.name }}
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div
            class="text-body-1 img-width-100"
            v-html="item.description"
          ></div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
export default {
  props: {
    tab: Object,
  },
};
</script>
<style>
img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
</style>
