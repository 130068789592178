<template>
  <v-row justify="center" v-if="isVisible">
    <v-dialog
      v-model="isVisible"
      persistent
      overlay-color="white"
      overlay-opacity="1"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="1300"
    >
      <v-card outlined flat :loading="isLoading">
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h6 font-weight-half-medium pl-0">
            <v-icon left color="#00AEBA" size="25" class="pb-1">mbi-notebook-outline</v-icon>
            <span>Новая заявка</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#e31e25" text dark x-small @click="cancel">
            <v-icon left dark>mdi-window-close</v-icon>
            Отмена
          </v-btn>
        </v-app-bar>
        <v-divider />
        <div class="mx-5 pt-3">
          <p>
            Подать онлайн-заявку на конкурсный отбор могут физические лица в возрасте от <b>7</b> до <b>17</b> лет.
          </p>
          <p>
            В случае положительной оценки Вашей заявки экспертами, Вы получите по электронной почте приглашение принять участие в одной из образовательных программ Фонда.
          </p>
          <p>
            Пожалуйста, заполните все поля формы и нажмите кнопку <b>Далее</b>
          </p>
        </div>
        <v-form class="mx-5 mb-5" v-model="isOrderValid">
          <v-row class="mt-4" justify="center" align="center">
            <v-col cols="12" sm="12" md="6" lg="8" xl="9">
              <v-autocomplete
                v-model="newOrder.direction"
                :items="directions"
                label="Направление"
                hint="Выберите направление"
                color="#af955c"
                no-data-text="Нет доступных направлений и курсов"
                dense
                clearable
                required
                item-text="name"
                item-value="id"
                :disabled="isLoading"
                return-object
                :rules="[rules.required]"
                :error="!newOrder.direction"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4" xl="3">
              <v-autocomplete
                v-model="newOrder.studyForm"
                :items="studyForms"
                label="Форма обучения"
                hint="Выберите форму обучения"
                color="#af955c"
                dense
                clearable
                item-text="name"
                item-value="id"
                return-object
                no-data-text="Нет форм обучения"
                required
                :disabled="!newOrder.direction || isLoading"
                :rules="[rules.required]"
                :error="!newOrder.studyForm"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <v-autocomplete
                v-model="newOrder.course"
                :items="courses"
                label="Курс"
                hint="Выберите курс"
                color="#af955c"
                dense
                clearable
                item-text="fName"
                item-value="id"
                return-object
                no-data-text="Нет подходящих курсов"
                required
                :disabled="!newOrder.direction || !newOrder.studyForm || isLoading"
                :rules="[rules.required]"
                :error="!newOrder.course"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="pt-3 pb-5" justify="center" align="center">
            <v-btn
              text
              :disabled="!isOrderValid || isLoading || isEmptyData" 
              :dark="isOrderValid && !isLoading"
              color="#af955c"
              @click="save"
            >
              Далее
              <v-icon right dark>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from 'axios';
export default {
  name: "OrderAdd",
  props: {
    isVisible: Boolean,
    rules: Object
  },
  data() {
    return {
      isOrderValid: false,
      isLoading: false,
      directions: [],
      studyForms: [],
      courses: [],
      newOrder: {
        direction: null,
        studyForm: null,
        course: null
      }
    };
  },
  computed: {
    isEmptyData(){
      return (this.directions && this.directions.length === 0) && 
      (this.studyForms && this.studyForms.length === 0) && 
      (this.course && this.course.length === 0);
    },
    newOrderStringify() {
      return JSON.stringify(this.newOrder);
    }
  },
  methods: {
    async getDirections() {
      try {
        let response = await axios.get("/api/getStudyDirections", {
          headers: {
            RequestVerificationToken: this.token,
            "X-Requested-With": "XMLHttpRequest",
          },
        });
        this.directions = response.data;
      }
      catch (error) {
        this.directions = [];
      }
      finally {
        if (this.newOrder.direction && !this.directions.find(d => d.id === this.newOrder.direction.id)){
          this.newOrder.direction = null;
        }        
      }
    },
    async getStudyForms() {
      if(this.newOrder.direction) {
        this.isLoading = true;
        try {
          let response = await axios.get(`/api/getStudyForms?directionId=${encodeURIComponent(this.newOrder.direction.id)}`, {
            headers: {
              RequestVerificationToken: this.token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });
          this.studyForms = response.data;
        }
        catch (error) {
          console.error(error);
          this.studyForms = [];
        }
        finally {
          if (this.newOrder.studyForm && !this.studyForms.find(d => d.id === this.newOrder.studyForm.id)){
            this.newOrder.studyForm = null;
          }          
          this.isLoading = false;
        }
      }
    },
    async getCourses() {
      if(this.newOrder.direction && this.newOrder.studyForm) {
        this.isLoading = true;
        try {
          let response = await axios.get(`/api/getCourses?directionId=${encodeURIComponent(this.newOrder.direction.id)}&studyFormId=${encodeURIComponent(this.newOrder.studyForm.id)}`, {
            headers: {
              RequestVerificationToken: this.token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });
          this.courses = response.data;
        }
        catch (error) {
          console.error(error);
          this.courses = [];
        }
        finally {
          if (this.newOrder.course && !this.courses.find(d => d.id === this.newOrder.course.id)){
            this.newOrder.course = null;
          }          
          this.isLoading = false;
        }
      }
    },
    setQueryData(directionId, studyFormId, courseId){
      if(directionId && studyFormId && courseId ){
        this.newOrder.direction = {
          id: directionId,
        };
        this.newOrder.studyForm = {
          id: studyFormId,
        };
        this.newOrder.course = {
          id: courseId,
        };
      }      
    },
    save() {
      if(!this.isLoading) {
        this.isLoading = true
        this.$emit('save', this.newOrder)
      }
    },
    cancel() {
      this.newOrder = {
        direction: null,
        studyForm: null,
        course: null
      }
      this.$emit('close');
    },
    async getData() {
      this.isLoading = true;
      await this.getDirections();
      this.isLoading = false;
    }
  },
  watch: {
    newOrderStringify: {
      handler(val, oldVal) {
        const oldData = JSON.parse(oldVal);
        const newData = JSON.parse(val);
        if(!newData.direction || !newData.studyForm) {
          this.newOrder.studyForm = null;
          this.newOrder.course = null;
        }

        if ((newData.direction && oldData.direction && newData.direction.id !== oldData.direction.id) || (!oldData.direction && newData.direction)) {
          this.getStudyForms();
        }

        if(this.courses.length === 0 && newData.direction && newData.studyForm) {
          this.getCourses();
        }
        else if((newData.direction && oldData.direction && newData.direction.id !== oldData.direction.id) || (newData.studyForm && oldData.studyForm && newData.studyForm.id !== oldData.studyForm.id)) {
          this.getCourses();
        }
      }
    }
  },
  created() {
    this.getData();
    this.setQueryData(
      this.$route.query.dirid, 
      this.$route.query.formid, 
      this.$route.query.courseid);
  }
};
</script>