<template>
  <div v-if="attach">
    <viewer 
      v-if="type !== 'Примеры выступлений' && attachExtension ==='image'" 
      class="text-left mt-2" 
      :options="imageOptions"
    >
      <img loading="lazy" class="preview-image" :src="`data:image/jpeg;base64,${encodeURI(attach.data)}`">
    </viewer>
    <div v-if="type !== 'Примеры выступлений' && attachExtension ==='file'">
      <span v-if="type !== 'Сертификат'" :class="'text-body-1 font-weight-half-medium'">
        Файл:
      </span>
      <span 
        :class="type !== 'Сертификат' ? 'text-body-1 file-link' : 'text-body-2 file-link__sertificate'"
        @click="openPdf"
      >
        {{attach.name}}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Attachment",
  data() {
    return {
      imageOptions: { 
        "button": true, 
        "navbar": false, 
        "title": true, 
        "toolbar": false, 
        "tooltip": false, 
        "movable": false, 
        "zoomable": true, 
        "rotatable": false, 
        "scalable": false, 
        "transition": true, 
        "fullscreen": false, 
        "keyboard": true 
      }
    }
  },
  props: {
    type: String,
    attach: Object
  },
  computed: {
    attachExtension() {
      if(this.attach && this.attach.data) {
        var firstChar = this.attach.data.charAt(0);
        if(firstChar === '/' || firstChar === 'i') return 'image';
        else return 'file';
      }
      else {
        return 'none';
      }
    }
  },
  methods: {
    openPdf() {
      const fileData = this.attach;
      let byteCharacters = atob(fileData.data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let file = new Blob([byteArray], { type: 'application/pdf;base64' });
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    },
  }
};
</script>

<style lang="scss">
  .preview-image {
    object-fit: contain;
    
    max-width: 275px;
    max-height: 175px;
    cursor: pointer;
    padding: 5px;
    display: inline-block;
    opacity: 0.8;
    transition: opacity 0.4s ease-in-out;

    &:hover {
      opacity: 1;
      transition: opacity 0.4s ease-in-out;
    }
  }  
  .file-link {
    cursor: pointer !important;

    &:hover {
      color: #7690c9 !important;
      text-decoration: underline !important;
    }
  }
  .file-link__sertificate {
    cursor: pointer !important;
    color: #7690c9 !important;
    text-decoration: underline !important;
    
    &:hover {
      color: rgba(0,0,0,.87) !important;
    }
  }

</style>