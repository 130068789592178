export default {
    firstName: null,
    lastName: null,
    middleName: null,
    birthdayDate: null,
    birthdayDateFormated: null,
    age: null,
    gender: null,
    clothing_size: null,
    login: null,
    password: null,
    passwordRepeated: null,
    course:{
      name: null,
      begin: null,
      end: null,
    },

    position: null,
    positionText: null,
    campShift: null,
    campShiftText: null,

    contacts: {
      address_fact: null,
      address_fact_house: null,
      address_fact_building: null,
      address_fact_flat: null,
      address_reg: null,
      address_reg_house: null,
      address_reg_building: null,
      address_reg_flat: null,
      email: null,
      phone: null,
    },
    education: {
      name: null,
      spec: null,
    },
    documents: {
      passport: null,
      passportSeries: null,
      passportNumber: null,
      snils: null,
      medpolis: null,
    },
  }