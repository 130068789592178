<template>
  <v-row justify="center" v-if="dialog">
    <v-dialog
      v-model="dialog"
      persistent
      overlay-color="white"
      overlay-opacity="1"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="1300"
    >
      <v-card outlined flat class="pb-4">
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h6 font-weight-half-medium pl-0">
            <v-icon left color="#00AEBA" size="25" class="pb-1">mdi-file-document</v-icon>
            <span>Новый документ</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#00AEBA" text dark x-small @click="cancel">
            <v-icon left dark>mdi-window-close</v-icon>
            Отмена
          </v-btn>
        </v-app-bar>
        <v-divider />
        <document-form
          :document="document"
          :rules="rules"
          :isNew="true"
          v-on:set-processing="setProcessing"
          v-on:save-document="saveDocument"
        ></document-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import DocumentForm from './DocumentForm.vue';
export default {
  name: "DocumentAdd",
  props: {
    dialog: Boolean,
    rules: Object,
  },
  data() {
    return {
      document: {
        id: null,
        //type: null,
        name: null,
        attach: null
      },
    };
  },
  components: {
    'document-form': DocumentForm
  },
  methods: {
    // async loadData() {

    // },
    setProcessing(state) {
      this.$emit('set-processing', state);
    },
    saveDocument(data) {
      this.$emit('save-document', data, 'newForm');
    },
    cancel() {
      this.$emit('cancel');
    }
  },
  created() {
    // this.loadData();
  }
};
</script>