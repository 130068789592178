<template>
  <v-container>
    <keep-alive>
      <profile
        ref="profile"
        v-if="$route.path === '/quals/profile'"
        :qualsInfo="qualsInfo"
        :rules="rules"
        :formatDate="formatDate"
        :parseDate="parseDate"
        :educationLevel="educationLevel"
        v-on:set-processing="setProcessing"
        v-on:save="saveProfile"
        v-on:change-password="changePassword"
        v-on:get-education="getEducationLevel"
      ></profile>
      <documents
        ref="documents"
        :documents="documents"
        :rules="rules"
        v-if="$route.path === '/quals/documents'"
        v-on:set-processing="setProcessing"
        v-on:save="saveDocuments"
        v-on:add-document="openNewDocumentDialog"
        v-on:delete-document="deleteDocument"
        v-on:show-snackbar="showSnackbar"
        v-on:get-documents="getDocuments"
      ></documents>
      <orders
        ref="orders"
        v-if ="$route.path === '/quals/orders'"
        :rules="rules"
        :formatDate="formatDate"
        :parseDate="parseDate"
        :orders="orders"
        :isEvent="isEvent"

        :documents="documents"
        :ordersTableLoading="ordersTableLoading"
        v-on:set-processing="setProcessing"

        v-on:change-order-state="changeOrderState"
        v-on:save-new-order="saveNewOrder"
        v-on:delete-order="deleteOrder"
        v-on:load-orders="getOrders"
        v-on:add-document="openNewDocumentDialog"
        v-on:select-document="selectOrderDocument"
        v-on:remove-document="removeOrderDocument"
      ></orders>
    </keep-alive>

    <v-snackbar
      v-model="resultSnackbar"
      class="text-center"
      vertical
      multi-line
      timeout="7000"
      :color="isResultSuccess ? '#af955c' : ''"
      :outlined="isResultSuccess"
    >
      <span class="text-center" v-html="resultText"></span>
      <template v-slot:action="{ attrs }">
        <v-btn
          :color="isResultSuccess ? '#af955c' : '#e31e25'"
          text
          v-bind="attrs"
          @click="
            () => {
              resultSnackbar = false;
              resultText = '';
            }
          "
        >
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>

    <document-add
      :dialog="newDocumentsDialog"
      :rules="rules"
      v-on:save-document="saveDocuments"
      v-on:cancel="closeNewDocumentDialog"
    >
    </document-add>

    <modal-dialog
      :isVisible="isError"
      :title="errorTitle"
      :text="errorText"
      :rightButtonText="errorRightButtonText"
      :rightButtonAction="errorRightButtonAction"
      :leftButtonText="errorLeftButtonText"
      :leftButtonAction="errorLeftButtonAction"
    >
    </modal-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import qualsInfo from "../../model/qualsInfo";
import inputRules from "../../model/inputRules";
import CustomModalDialog from "../../components/ModalDialog.vue";
import DocumentsVue from "../../components/quals/Documents.vue";
import DocumentAddVue from "../../components/quals/DocumentAdd.vue";
import ProfileVue from "../../components/quals/Profile.vue";
import OrdersVue from '../../components/quals/Orders.vue';

export default {
  name: "AccountView",
  data: () => ({
    token: null,
    qualsInfo: null,
    educationLevel: [],
    documents: [],
    rules: { ...inputRules },
    isMacroExecuting: false,
    isError: false,
    errorTitle: null,
    errorText: null,
    errorRightButtonText: null,
    errorRightButtonAction: null,
    errorLeftButtonText: null,
    errorLeftButtonAction: null,
    orders: [],
    resultSnackbar: false,
    resultText: "",
    isResultSuccess: false,

    ordersTableLoading: false,
    newDocumentsDialog: false,
    addDocumentForOrderId: undefined,
  }),
  components: {
    profile: ProfileVue,
    documents: DocumentsVue,
    'orders': OrdersVue,
    "modal-dialog": CustomModalDialog,
    "document-add": DocumentAddVue,
  },
  methods: {
    getToken() {
      let tokenElements = document.getElementsByName(
        "__RequestVerificationToken"
      );
      if (tokenElements && tokenElements.length > 0)
        this.token = tokenElements[0].value;
    },
    isEvent(type) {
      return type === "Победы";
    },
    openNewDocumentDialog(orderId) {
      if (this.$refs.documents) {
        this.$refs.documents.editingDocument = null;
      }
      this.newDocumentsDialog = true;
      if (orderId) {
        this.addDocumentForOrderId = orderId;
      }
    },
    closeNewDocumentDialog() {
      this.newDocumentsDialog = false;
      this.addDocumentForOrderId = undefined;
    },
    showSnackbar(success, text) {
      this.isResultSuccess = success;
      this.resultText = text;
      this.resultSnackbar = true;
    },
    setProcessing(state) {
      this.$emit("set-processing", state);
    },
    signOut() {
      window.location.href = "/Auth/SignOut";
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split(".");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    // formatQualsListener(qualsData) {
    //   if(qualsData.documents.snils && typeof qualsData.documents.snils === 'string')
    //   qualsData.documents.snils = qualsData.documents.snils ? qualsData.documents.snils.replaceAll(' ', '').replaceAll('-', '') : '';
    // return qualsData;
    // },
    async saveProfile(qualsData, type) {
      this.setProcessing(true);
      const isAuth = await this.checkUserAuthenticate();
      if (isAuth !== true) {
        this.setProcessing(false);
        return;
      }
      try {
        let response = await axios.post(
          "/do/api?macro=api_quals_change_profile_settings", qualsData);
        this.saveProfileResponse(response.data, type);
      } catch (error) {
        console.error(error);
        var data = {
          success: false,
          message: error.message,
        };
        this.saveProfileResponse(data, type);
      }
    },
    async saveProfileResponse(data, type) {
      if (data.success) {
        await this.getQualsInfo();

        if (type === "profile") {
          this.$refs.profile.isGeneralEditing = false;
          this.$refs.profile.isContactsEditing = false;
          this.$refs.profile.isEducationEditing = false;
          this.$refs.profile.isChangingPassword = false;
        }
        this.showSnackbar(true, "Изменения успешно сохранены");
      } else {
        this.setProcessing(false);
        this.showSnackbar(false, data.message);
      }
    },
    async getQualsInfo() {
      this.setProcessing(true);
      try {
        let response = await axios.get("/api/getQualsInfo", {
          headers: {
            RequestVerificationToken: this.token,
            "X-Requested-With": "XMLHttpRequest",
          },
        });
        if (response.data.success === false)
          throw new Error("Ошибка загрузки пользователя");
        this.qualsInfo = response.data;
      } catch (error) {
        console.error(error);
        this.showCriticalError(
          "Ошибка загрузки",
          "При загрузке данных произошла ошибка. Пожалуйста, обновите страницу. Если ошибка повторяется, обратитесь в службу поддержки."
        );
      } finally {
        this.setProcessing(false);
      }
    },

    async getDocuments() {
      this.setProcessing(true);
      try {
        let response = await axios.get("/api/getQualsDocuments", {
          headers: {
            RequestVerificationToken: this.token,
            "X-Requested-With": "XMLHttpRequest",
          },
        });
        if (response.data.success === false)
          throw new Error("Ошибка загрузки документов");
        this.documents = response.data;
        await this.$nextTick();
      } catch (error) {
        console.error(error);
        this.showCriticalError(
          "Ошибка загрузки",
          "При загрузке данных произошла ошибка. Пожалуйста, обновите страницу. Если ошибка повторяется, обратитесь в службу поддержки."
        );
      } finally {
        this.setProcessing(false);
      }
    },

    async getEducationLevel() {
      this.isLoading = true;
      try {
        let response = await axios.get("/api/getEducationLevel", {
          headers: {
            RequestVerificationToken: this.token,
            "X-Requested-With": "XMLHttpRequest",
          },
        });
        this.educationLevel = response.data;
        this.educationLevel.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
      } catch (error) {
        this.educationLevel = [];
      } finally {
        this.isLoading = false;
      }
    },


    async checkUserAuthenticate() {
      await this.$getUserAuthenticate();
      let isAuthenticated = this.$isAuthenticated;
      if (!isAuthenticated) {
        this.setProcessing(false);
        this.isError = true;
        this.errorTitle = "Ошибка сохранения";
        this.errorText =
          "Для продолжения работы необходимо авторизоваться в личном кабинете. Пожалуйста, перейдите на страницу авторизации.";
        this.errorRightButtonText = "Перейти";
        this.errorRightButtonAction = this.reloadPage;

        return false;
      } else {
        return true;
      }
    },

    async saveDocuments(document, from) {
      if (this.isMacroExecuting === true) return;
      this.setProcessing(true);
      const isAuth = await this.checkUserAuthenticate();
      if (isAuth !== true) {
        this.setProcessing(false);
        return;
      }
      let documentsForSend = { ...document };

      let macroData = new FormData();
      macroData.append("macroName", "api_quals_document_set");
      macroData.append("payload", JSON.stringify(documentsForSend));
      macroData.append("file", document.attach);

      try {
        this.isMacroExecuting = true;
        let response = await axios.post("/api/callMacro", macroData, {
          headers: {
            "Content-Type": "multipart/form-data",
            RequestVerificationToken: this.token,
            FormDataXHR: true,
          },
        });
        response.data.document = {
          name: documentsForSend.name,
        };
        const addedDocument = await this.saveDocumentResponse(response.data, from);
        if (this.addDocumentForOrderId && addedDocument) {
          const selectedDocument = {
            id: addedDocument.id,
            name: addedDocument.name,
            orderId: this.addDocumentForOrderId
          };
          this.selectOrderDocument(selectedDocument);
        }
      } catch (error) {
        console.error(error);
        var data = {
          success: false,
          message: error.message,
        };
        this.saveDocumentResponse(data, from);
      }
    },

    async saveDocumentResponse(data, from) {
      this.isMacroExecuting = false;
      if (data.success) {
        await this.getDocuments();

        if (from === "changeForm") {
          this.$refs.documents.editingDocument = null;
          this.showSnackbar(true, "Изменения успешно сохранены");
        } else if (from === "newForm") {
          await this.$nextTick();
          let addedDocument;
          if (data.document && data.document.name) {
            addedDocument = this.documents.find((n) => {
              return n.name === data.document.name;
            });
            if (addedDocument && this.$refs.documents) {
              this.$refs.documents.$refs[addedDocument.id][0].panel = 0;
            }
          }

          this.newDocumentsDialog = false;
          this.showSnackbar(true, "Документ успешно добавлен");
          return addedDocument;
        }
      } else {
        this.setProcessing(false);
        this.showSnackbar(false, data.message);
      }
    },
    async deleteDocument(id) {
      this.setProcessing(true);
      const isAuth = await this.checkUserAuthenticate();
      if(isAuth !== true) {
        this.setProcessing(false);
        return;
      }
      let documentData = new FormData();
      documentData.append("id", id);
      try {
        let response = await axios.post('api/deleteQualsDocument', documentData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            'RequestVerificationToken': this.token
            },
          });
        this.deleteDocumentResponse(response.data);
      } 
      catch(error) {
        console.log(error);
        var data = {
          success: false,
          message: error.message
        }
        this.deleteDocumentResponse(data);
      }
    },
    async deleteDocumentResponse(data) {
      if (data.success) {
        this.$refs.documents.editingDocument = null;
        await this.getDocuments();
        this.showSnackbar(true, "Документ успешно удален");
      } else {
        this.setProcessing(false);
        this.showSnackbar(false, data.message);
      }
    },

    async changePassword(newPassword) {
      this.setProcessing(true);
      const isAuth = await this.checkUserAuthenticate();
      if (isAuth !== true) {
        this.setProcessing(false);
        return;
      }
      const profileData = {
        profile: {
          name: this.qualsInfo.login,
          email: this.qualsInfo.contacts.email,
        },
        password: newPassword,
      };
      let macroData = new FormData();
      macroData.append("profile", JSON.stringify(profileData.profile));
      macroData.append("password", profileData.password);
      try {
        let response = await axios.post(
          "/security/updateuseroptions",
          macroData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );
        this.saveChangePasswordResponse(response.data);
      } catch (error) {
        console.error(error);
        var data = {
          success: false,
          message: error.message,
        };
        this.saveChangePasswordResponse(data);
      }
    },
    async saveChangePasswordResponse(data) {
      this.setProcessing(false);
      if (data.success) this.showSnackbar(true, "Изменения успешно сохранены");
      else this.showSnackbar(false, "Ошибка смены пароля");
    },
    showCriticalError(errorTitle, errorText) {
      this.isError = true;
      this.errorTitle = errorTitle;
      this.errorText = errorText;
      this.errorRightButtonText = "Обновить";
      this.errorRightButtonAction = this.reloadPage;
      this.errorLeftButtonText = "Выйти";
      this.errorLeftButtonAction = this.signOut;
    },
    reloadPage() {
      window.location.reload();
    },
    async getOrders() {
      this.ordersTableLoading = true;
      try {
        let response = await axios.get("/api/getQualsOrders", {
          headers: {
            RequestVerificationToken: this.token,
            "X-Requested-With": "XMLHttpRequest",
          },
        });
          if(response.data.success === false) throw new Error("Ошибка загрузки заявок");
        this.orders = response.data;
        // Test objs
        //testOrders.forEach(to => this.orders.push(to));
        } 
        catch (error) {
        console.error(error);
          this.showCriticalError('Ошибка загрузки', 'При загрузке данных произошла ошибка. Пожалуйста, обновите страницу. Если ошибка повторяется, обратитесь в службу поддержки.');
        }
        finally {
        this.ordersTableLoading = false;
      }
    },

    async deleteOrder(id) {
      this.ordersTableLoading = true;
      const isAuth = await this.checkUserAuthenticate();
        if(isAuth !== true) {
        this.ordersTableLoading = false;
        return;
      }
      let orderData = new FormData();
      orderData.append("id", id);
      try {
          let response = await axios.post('/api/deleteQualsOrder', orderData, {
          headers: {
              'Content-Type': 'multipart/form-data',
              'RequestVerificationToken': this.token
          },
        });
        this.deleteOrderResponse(response.data);
        }
        catch(error) {
        console.error(error);
        var data = {
          success: false,
            message: error.message
          }
        this.deleteOrderResponse(data);
      }
    },
    async deleteOrderResponse(data) {
        if(data.success) {
          if(this.$refs.orders && this.$refs.orders.openedOrder) {
          this.$refs.orders.closeOrder();
        }
        await this.getOrders();
          this.showSnackbar(true, 'Заявка успешно удалена');
        }
        else {
        this.ordersTableLoading = false;
        this.showSnackbar(false, data.message);
      }
    },
    async changeOrderState(order, statename) {
        if(this.isMacroExecuting === true) return;
      this.ordersTableLoading = true;
      const isAuth = await this.checkUserAuthenticate();
        if(isAuth !== true) {
        this.ordersTableLoading = false;
        return;
      }

      const orderData = {
        id: order.id,
        courseid: order.course.id,
          statename: statename
      };
      
      let macroData = new FormData();
      macroData.append("macroName", "api_quals_request_set");
      macroData.append("payload", JSON.stringify(orderData));

      try {
        this.isMacroExecuting = true;
          let response = await axios.post('/api/callMacro', macroData, {
          headers: {
              'Content-Type': 'multipart/form-data',
              'RequestVerificationToken': this.token
          },
        });
        this.changeOrderStateResponse(response.data, statename);
        }
        catch(error) {
        console.error(error);
        var data = {
          success: false,
            message: "Ошибка сохранения. Пожалуйста, повторите попытку или обратитесь в службу поддержки."
          }
        this.changeOrderStateResponse(data, statename);
      }
    },
    async changeOrderStateResponse(data, statename) {
      this.isMacroExecuting = false;
        if(data.success) {          
        await this.getOrders();
          const message = statename === 'Новая' ? 'Заявка успешно подана на рассмотрение' : 'Заявка возвращена на редактирование';
        this.showSnackbar(true, message);
        }
        else {
        this.showSnackbar(false, data.message);
        this.ordersTableLoading = false;
      }
    },
    async saveNewOrder(order) {
        if(this.isMacroExecuting === true) return;

      const isAuth = await this.checkUserAuthenticate();
        if(isAuth !== true) {
        this.$refs.orders.$refs.newOrder.isLoading = false;
        return;
      }
      const orderData = {
        id: null,
        courseid: order.course.id,
        comment: order.comment,
          statename: null
      };

      let macroData = new FormData();
      macroData.append("macroName", "api_quals_request_set");
      macroData.append("payload", JSON.stringify(orderData));

      try {
        this.isMacroExecuting = true;
          await this.$nextTick()
          let response = await axios.post('/api/callMacro', macroData, {
          headers: {
              'Content-Type': 'multipart/form-data',
              'RequestVerificationToken': this.token
          },
        });
        let responseData = response.data;
        responseData.order = order;
        this.saveNewOrderResponse(responseData);
        }
        catch(error) {
        console.error(error);
        var data = {
          success: false,
            message: "Ошибка сохранения. Пожалуйста, повторите попытку или обратитесь в службу поддержки."
          }
        this.saveNewOrderResponse(data);
        }
        finally {
        this.$refs.orders.$refs.newOrder.isLoading = false;
      }
    },
    async saveNewOrderResponse(data) {
      this.isMacroExecuting = false;
        if(data.success) {
        this.$refs.orders.isOrderCreating = false;
        await this.getOrders();
          const message = 'Заявка успешно создана. Пожалуйста, приложите подходящие достижения и документы. Далее отправьте заявку на рассмотрение.';
        this.showSnackbar(true, message);
          const orderToOpen = this.orders.filter(o => o.course.id === data.order.course.id)[0];
        this.$refs.orders.openOrder(orderToOpen);
        }
        else {
        this.showSnackbar(false, data.message);
        this.$refs.orders.$refs.newOrder.isLoading = false;
      }
    },
    async selectOrderDocument(document) {
        if(this.isMacroExecuting === true) return;
      this.ordersTableLoading = true;
      const isAuth = await this.checkUserAuthenticate();
        if(isAuth !== true) {
        this.ordersTableLoading = false;
        return;
      }

      const documentData = {
        id: null,
        quals_requestid: document.orderId,
        quals_student_documentid: document.id
      };

      let macroData = new FormData();
      macroData.append("macroName", "api_quals_request_document_set");
      macroData.append("payload", JSON.stringify(documentData));

      try {
        this.isMacroExecuting = true;
          let response = await axios.post('/api/callMacro', macroData, {
          headers: {
              'Content-Type': 'multipart/form-data',
              'RequestVerificationToken': this.token
          },
        });
        this.selectOrderDocumentResponse(response.data);
        }
        catch(error) {
        console.error(error);
        var data = {
          success: false,
            message: "Ошибка сохранения. Пожалуйста, повторите попытку или обратитесь в службу поддержки."
          }
        this.selectOrderDocumentResponse(data);
      }
    },
    async selectOrderDocumentResponse(data) {
      this.isMacroExecuting = false;
        if(data.success) {
        await this.getOrders();
        await this.getDocuments();
        }
        else {
        this.showSnackbar(false, data.message);
        this.ordersTableLoading = false;
      }
    },
    async removeOrderDocument(id, orderId) {
      this.ordersTableLoading = true;
      const isAuth = await this.checkUserAuthenticate();
        if(isAuth !== true) {
        this.ordersTableLoading = false;
        return;
      }
      let documentData = new FormData();
      documentData.append("id", id);
      documentData.append("orderId", orderId);
      try {
          let response = await axios.post('/api/removeQualsOrderDocument', documentData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'RequestVerificationToken': this.token
            },
          });
        this.removeOrderDocumentResponse(response.data);
        }
        catch(error) {
        console.error(error);
        var data = {
          success: false,
            message: error.message
          }
        this.removeOrderDocumentResponse(data);
      }
    },
    async removeOrderDocumentResponse(data) {
        if(data.success) {
        await this.getOrders();
        await this.getDocuments();
        }
        else {
        this.ordersTableLoading = false;
        this.showSnackbar(false, data.message);
      }
    },
  },
  created() {
    this.getToken();
    this.getQualsInfo();
    this.getEducationLevel();
    this.getDocuments();
    this.getOrders();
  },
};
</script>

<style lang="scss">
body {
  scroll-behavior: smooth;
}
.font-weight-half-medium {
  font-weight: 400 !important;
}
</style>
