<template>
  <div>
    <p class="text-h5 font-weight-light mt-3">Мои заявки</p>
    <v-divider></v-divider>
    <v-row
      class="mt-3" 
      justify="center" 
      align="center"
    >
      <v-btn
        color="#7690c9"
        class="ma-2"
        text
        dark
        @click="createOrder"
      >
        <v-icon left dark>
          mdi-plus
        </v-icon>
        Создать заявку
      </v-btn>
    </v-row>
    <v-row justify="center" align="center">
      <orders-table 
        :orders="orders"
        :ordersTableLoading="ordersTableLoading"
        v-on:open-order="openOrder"
        v-on:change-order-state="changeOrderState"
        v-on:delete-order="deleteOrder"
      ></orders-table>
    </v-row>
    <order-dialog
      :ordersTableLoading="ordersTableLoading"
      :isOpen="isOrderDialogOpened" 
      :order="openedOrder"
      :documents="documents"
      :achievements="achievements"
      :isEvent="isEvent"
      v-on:close-order="closeOrder"
      v-on:change-order-state="changeOrderState"
      v-on:delete-order="deleteOrder"
      v-on:add-achievement="(orderId) => $emit('add-achievement', orderId)"
      v-on:select-achievement="selectAchievement"
      v-on:remove-achievement="removeAchievement"
      v-on:add-document="(orderId) => $emit('add-document', orderId)"
      v-on:select-document="selectDocument"
      v-on:remove-document="removeDocument"
    ></order-dialog>
    <modal-dialog
      :isVisible="modalDialog.isVisible"
      :title="modalDialog.title"
      :text="modalDialog.text"
      :rightButtonText="modalDialog.rightButtonText"
      :rightButtonAction="modalDialog.rightButtonAction"
      :leftButtonText="modalDialog.leftButtonText"
      :leftButtonAction="modalDialog.leftButtonAction"
    >
    </modal-dialog>
    <new-order
      ref="newOrder"
      v-if="isOrderCreating"
      :rules="rules" 
      :isVisible="isOrderCreating"
      v-on:save="saveNewOrder"
      v-on:close="isOrderCreating = false"     
    ></new-order>
  </div>
</template>

<script>
  import axios from 'axios';
  import OrdersTableVue from './OrdersTable.vue';
  import OrderDialogVue from './OrderDialog.vue';
  import CustomModalDialog from '../components/ModalDialog.vue';
  import OrderAddVue from './OrderAdd.vue';

  export default {
    name: "Orders",
    props: {
      orders: Array,
      achievements: Array,
      documents: Array,
      formatDate: Function,
      parseDate: Function,
      rules: Object,
      isEvent: Function,
      ordersTableLoading: Boolean
    },
    data: () => ({
      isOrderCreating: false,
      isOrderDialogOpened: false,
      openedOrder: null,

      modalDialog: {
        isVisible: false,
        title: null,
        text: null,
        payload: null,
        rightButtonText: null,
        rightButtonAction: null,
        leftButtonText: null,
        leftButtonAction: null
      }
    }),
    components: {
      'orders-table': OrdersTableVue,
      'order-dialog': OrderDialogVue,
      'modal-dialog': CustomModalDialog,
      'new-order': OrderAddVue
    },
    computed: {
      ordersStringify() {
        return JSON.stringify(this.orders);
      }
    },
    methods: {
      saveNewOrder(order) {
        this.$emit('save-new-order', order);
      },
      openOrder(order) {
        this.openedOrder = order;
        this.isOrderDialogOpened = true;
        
        this.loadOrders();
      },
      closeOrder() {
        this.openedOrder = null;
        this.isOrderDialogOpened = false;
      },
      deleteOrder(id) {
        this.modalDialog.title = "Удаление заявки";
        this.modalDialog.text = "Вы уверены, что хотите <strong>удалить</strong> выбранную заявку? Данное действие необратимо, ваша заявка будет полностью удалена из системы.";
        this.modalDialog.payload = id;
        this.modalDialog.rightButtonText = "Удалить";
        this.modalDialog.rightButtonAction = this.confirmDelete;
        this.modalDialog.leftButtonText = "Отмена";
        this.modalDialog.leftButtonAction = this.closeModalDialog;
        this.modalDialog.isVisible = true;
      },
      confirmDelete() {
        this.modalDialog.isVisible = false;
        this.$emit('delete-order', this.modalDialog.payload);
        this.modalDialog.payload = null;
        this.modalDialog.title = null;
        this.modalDialog.text = null;
        this.modalDialog.rightButtonText = null;
        this.modalDialog.rightButtonAction = null;
        this.modalDialog.leftButtonText = null;
        this.modalDialog.leftButtonAction = null;
      },
      closeModalDialog() {
        this.modalDialog.isVisible = false;
        this.modalDialog.payload = null;
        this.modalDialog.title = null;
        this.modalDialog.text = null;
        this.modalDialog.rightButtonText = null;
        this.modalDialog.rightButtonAction = null;
        this.modalDialog.leftButtonText = null;
        this.modalDialog.leftButtonAction = null;
      },
      selectAchievement(achievement) {
        this.$emit('select-achievement', achievement);
      },
      removeAchievement(id, orderId) {
        this.$emit('remove-achievement', id, orderId);
      },
      selectDocument(document) {
        this.$emit('select-document', document);
      },
      removeDocument(id, orderId) {
        this.$emit('remove-document', id, orderId);
      },
      createOrder() {
        this.isOrderCreating = true;
      },
      changeOrderState(order, statename) {
        this.$emit('change-order-state', order, statename);
      },
      loadOrders(){
        this.$emit('load-orders');
      },
      hasQueryData(){
        return this.$route.query.dirid && 
          this.$route.query.formid && 
          this.$route.query.courseid;
      }
    },
    watch: {
      ordersStringify: {
        handler(val) {
          if(this.openedOrder) {
            this.openedOrder = { ...this.orders.filter(o => o.id === this.openedOrder.id)[0] }
          }
        }
      }
    },
    created(){
      this.isOrderCreating = this.hasQueryData();
    },
  };
</script>