<template>
  <v-row justify="center" v-if="isOpen">
    <v-dialog
      v-model="isOpen"
      persistent
      overlay-color="white"
      overlay-opacity="1"
      :fullscreen="true"
    >
      <order-card 
        :order="order" 
        :documents="documents"
        :ordersTableLoading="ordersTableLoading"
        :isEvent="isEvent"
        v-on:close-order="$emit('close-order')"
        v-on:change-order-state="changeOrderState"
        v-on:delete-order="deleteOrder"
        v-on:add-document="$emit('add-document', order.id)"
        v-on:select-document="selectDocument"
        v-on:remove-document="removeDocument"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import OrderCardVue from './OrderCard.vue';
export default {
  name: "OrderDialog",
  props: {
    isOpen: Boolean,
    order: Object,
    isEvent: Function,
    documents: Array,
    ordersTableLoading: Boolean
  },
  components: {
    'order-card': OrderCardVue
  },
  methods: {
    selectDocument(document) {
      this.$emit('select-document', document);
    },
    removeDocument(id, orderId) {
      this.$emit('remove-document', id, orderId);
    },
    changeOrderState(order, statename) {
      this.$emit('change-order-state', order, statename);
    },
    deleteOrder(id) {
      this.$emit('delete-order', id);
    }
  }
};
</script>